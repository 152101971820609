import axios from 'axios';

const mockUserProducts = [
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' },
  { img: 'https://mcdonalds.ge/eb487867e6be-resized.png', name: 'მაქს ბურგერი' }
];
export class ProductsService {
  async getProducts(index, branchId) {
    let response = await axios.get(
      `/api/Manager/GetProducts?index=${index}${branchId ? `&branchId=${branchId}` : ''}`
    );
    return response.data;
  }

  async deleteProduct(id) {
    const res = await axios.delete(`/api/Manager/RemoveProduct?productId=${id}`);
    return res;
  }

  async addProduct(productData) {
    const data = new FormData();
    data.append('name', productData.name);
    data.append('categoryId', Number(productData.categoryId));
    data.append('price', Number(productData.price));
    data.append('primeCost', Number(productData.primeCost));
    data.append('Image', productData.Image);
    data.append('StorageProductId', productData.storageProductId);
    data.append('StorageProductQuanitity', Number(productData.storageProductQuanitity));
    const res = await axios.post('/api/Manager/AddProduct', data);

    const { id } = res.data;

    const { ingredients } = productData;

    for (const ingredient of ingredients) {
      await axios.post('/api/Manager/AddIngredientsInProduct', {
        productId: id,
        ingredient: ingredient
      });
    }
  }

  async editProduct(productData) {
    const data = new FormData();
    data.append('name', productData.name);
    data.append('categoryId', Number(productData.categoryId));
    data.append('price', Number(productData.price));
    data.append('Image', productData.Image ?? null);
    data.append('StorageProductId', productData.storageProductId);
    data.append('StorageProductQuanitity', Number(productData.storageProductQuanitity));
    await axios.put(`/api/Manager/UpdateProduct?productId=${productData.id}`, data);

    const { ingredients, id } = productData;

    for (const ingredient of ingredients) {
      await axios.post('/api/Manager/AddIngredientsInProduct', {
        productId: id,
        ingredient: typeof ingredient === 'object' ? ingredient.name : ingredient
      });
    }
  }

  async getUserProducts() {
    return mockUserProducts;
  }
}
