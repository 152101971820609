import React, { useState } from 'react';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DELIVERY_PRICE, ingredientTypes, Roles, theme } from 'data';
import {
  TextField,
  Typography,
  ListItemIcon,
  InputLabel,
  Select,
  FormControl
} from '@mui/material';
import { Delete, Edit } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { LoadButton } from 'components/LoadButton/LoadButton';

export const sidebarWidth = 350;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: sidebarWidth
  },
  drawerPaper: {
    minWidth: sidebarWidth,
    padding: 17
  },
  logoContainer: {
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center'
  },
  logoImgContainer: {
    width: '45px',
    marginRight: '15px',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  topList: {
    flex: 1,
    padding: '20px 0',
    marginBottom: '20px'
  },
  listItem: {
    color: theme.palette.neutralPrimary,
    fill: theme.palette.neutralPrimary,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '0 !important',
    paddingRight: '0 !important'
  },
  orderPart: {
    marginTop: 'auto',
    marginBottom: '10%'
  },
  order: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  quantityInput: {
    width: 65
  },
  input: {
    width: '100%',
    marginBottom: '22px !important'
  }
}));

export const Cart = (props) => {
  const [loading, setLoading] = useState(false);
  const [cashValue, setCashValue] = useState('');
  const classes = useStyles();
  const ROLE = useSelector((state) => state.user.role);
  const branches = useSelector((state) => state.branches.branches);
  const price =
    props.order.orders.reduce((total, current) => total + current.quantity * current.price, 0) +
    (props.order.isDelivery ? DELIVERY_PRICE : 0);

  const addOrder = async () => {
    try {
      setLoading(true);
      await props.placeOrder();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const isCashier = ROLE === Roles.cashier;

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      anchor='right'
      variant='permanent'>
      <List className={classes.topList}>
        {props.order.orders.map((val, i) => {
          return (
            <React.Fragment key={val.id}>
              <ListItem className={classes.listItem}>
                <div>
                  <ListItemText style={{ marginBottom: 10 }} primary={val.name} />
                  <TextField
                    onChange={(e) => props.handleOrdersItemChange(i, e.target.value)}
                    type='number'
                    className={classes.quantityInput}
                    InputProps={{ endAdornment: 'X' }}
                    label='რაოდენობა'
                    variant='standard'
                    value={val.quantity}
                  />
                </div>
                <ListItemIcon style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Delete style={{ marginRight: 10 }} onClick={() => props.handleCartRemove(i)} />
                  <Edit onClick={() => props.handleEdit(val, i)} />
                </ListItemIcon>
              </ListItem>
              <List>
                {val.ingredients.map((val) => {
                  return (
                    <div
                      style={{
                        background:
                          (val.type === 4 && 'green') ||
                          (val.type === 2 && 'red') ||
                          (val.type === 1 && 'red')
                      }}>
                      <ListItemText
                        secondary={`${val.name} - ${
                          ingredientTypes.find((e) => e.id === val.type).name
                        }`}
                      />
                    </div>
                  );
                })}
              </List>
              {i !== props.order.orders.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
      <div className={classes.orderPart}>
        <div
          style={{
            padding: '0 5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px'
          }}>
          <Typography variant='h5'>ფასი:</Typography>
          <Typography variant='h5'>{price}ლ</Typography>
        </div>
        <div className={classes.order}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>შეკვეთის ტიპი</InputLabel>
            <Select
              value={props.order.orderType}
              label='შეკვეთის ტიპი'
              className={classes.input}
              disabled={loading}
              onChange={(ev) => props.handleOrderItemChange('orderType', ev.target.value)}>
              {ROLE !== Roles.operator && <MenuItem value={'INSIDE'}>დარბაზში</MenuItem>}
              <MenuItem value={'TAKEAWAY'}>წაღებით</MenuItem>
              {ROLE !== Roles.cashier ? <MenuItem value={'DELIVERY'}>გამოძახებით</MenuItem> : null}
            </Select>
          </FormControl>
          <TextField
            variant='outlined'
            label='კომენტარი'
            onChange={(e) => props.handleOrderItemChange('description', e.target.value)}
            value={props.order.description}
            className={classes.input}
            disabled={loading}
          />
          {ROLE !== Roles.cashier && (
            <React.Fragment>
              <TextField
                variant='outlined'
                label='ნომერი'
                onChange={(e) => props.handleOrderItemChange('phone', e.target.value)}
                value={props.order.phone}
                className={classes.input}
                disabled={loading}
              />
              <TextField
                variant='outlined'
                label='მისამართი'
                onChange={(e) => props.handleOrderItemChange('address', e.target.value)}
                value={props.order.address}
                className={classes.input}
                disabled={loading}
              />
              <TextField
                style={props.order.orderType !== 'DELIVERY' ? { display: 'none' } : {}}
                type='number'
                variant='outlined'
                label='კურიერის საკომისიო'
                onChange={(e) => props.handleOrderItemChange('deliveryPrice', e.target.value)}
                value={props.order.deliveryPrice}
                className={classes.input}
                disabled={loading}
              />
              <FormControl className={classes.input}>
                <InputLabel htmlFor='product-name-selector'>ფილიალი</InputLabel>
                <Select
                  onChange={(e) =>
                    props.handleOrderItemChange('branchId', parseInt(e.target.value))
                  }
                  value={props.order.branchId}
                  variant='outlined'
                  labelId='product-name-selector'
                  disabled={loading}
                  label='პროდუქტი'>
                  {branches.map((val) => {
                    return <MenuItem value={val.id}>{val.addres} - {val.city}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </React.Fragment>
          )}
          <div>
            <FormControlLabel
              control={
                <Radio
                  value={'CARD'}
                  onChange={props.togglePayType}
                  checked={props.order.payType === 'CARD'}
                />
              }
              label='ბარათით'
            />
            <FormControlLabel
              control={
                <Radio
                  value={'CASH'}
                  onChange={props.togglePayType}
                  checked={props.order.payType === 'CASH'}
                />
              }
              label='ქეშით'
            />
          </div>

          {props.order.payType === 'CASH' && price && isCashier ? (
            <div
              style={{
                margin: '1em 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <TextField
                value={cashValue}
                onChange={(ev) => setCashValue(ev.target.value)}
                type='number'
                variant='outlined'
                label='თანხა'
              />
              <h3 style={{ marginLeft: '1em', opacity: cashValue >= price ? 1 : 0 }}>
                {Number(cashValue) - price}₾
              </h3>
            </div>
          ) : null}

          <LoadButton
            variant='contained'
            disableElevation
            loading={loading}
            disabled={loading || !props.order.orders.length || !props.order.payType}
            onClick={addOrder}
            style={{
              background: theme.palette.themePrimary,
              color: theme.palette.white,
              width: '100%',
              padding: '10px',
              fontSize: '1.12em'
            }}>
            შეკვეთა
          </LoadButton>
        </div>
      </div>
    </Drawer>
  );
};
