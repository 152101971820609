import styled from 'styled-components';

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  position: absolute;
  width: 91%;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  & > svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const ConfirmButton = styled.button`
  width: 80%;
  padding: 16px;
  margin: 50px auto auto auto;
  background-color: #00a852;
  border-radius: 7px;
  color: white;
  font-size: 1.08em;
  border: none;
  outline: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > svg {
    margin-left: 10px;
    font-size: 18px;
  }
`;

export const CodeContainer = styled.div``;

export const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CodeHeader = styled.p`
  margin-bottom: 25px;
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: 500;
`;

export const SendAgain = styled.div`
  margin-top: 15px;
`;

export const SendAgainLink = styled.p`
  display: inline-block;
  color: #4194F3;
  text-decoration: underline;
  cursor: pointer;
`;
