import styled from 'styled-components';

const BORDER_RADIUS = '23px';

export const Product = styled.div`
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
`;

export const Img = styled.div`
  width: 100%;
  height: 170px;
  & > img {
    width: 100%;
    border-radius: ${BORDER_RADIUS};
    height: 100%;
    object-fit: cover;
  }
`;

export const Description = styled.div`
  padding: 25px 13px 15px 13px;
`;

export const Name = styled.p`
  font-size: 1.2em;
  font-weight: 600;
`;

export const DecsRow = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

export const Icons = styled.div`
  & > svg {
    width: 20px;
    height: 20px;
    color: #00a852;
  }
`;

export const Cat = styled.div`
  font-weight: 500;
  font-size: 0.84em;
  color: #aaaaaa;
`;

export const Price = styled.p`
  color: #00a852;
  font-weight: 600;
  font-size: 1.07em;
`;
