import axios from 'axios';

export class MeasurementService {
  async addMeasurement(measurement) {
    const res = await axios.post(`/Storage/AddMeasurmentType?Measurment=${measurement}`);
    return res;
  }

  async deleteMeasurement(id) {
    const res = await axios.delete(`/Storage/RemoveMeasurment?Id=${id}`);
    return res;
  }

  async editMeasurement(id, name) {
    const res = await axios.delete(`/Storage/RemoveMeasurment`, {
      id,
      name
    });
    return res;
  }
}
