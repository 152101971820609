import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CourierService } from 'services';

export const ActiveCouriers = () => {
  const [state, setState] = useState([]);
  const [refresh, setRefresh] = useState(Date.now());

  const theme = useTheme();

  const courierService = useMemo(() => new CourierService(), []);

  useEffect(() => {
    const getActiveCouriers = async () => {
      const res = await courierService.getActiveCouriers();
      setState(res);
    };

    getActiveCouriers();
  }, [courierService, refresh]);

  return (
    <div>
      <Button
        onClick={() => setRefresh(Date.now())}
        variant='primary'
        style={{ backgroundColor: 'red', color: 'white', marginBottom: 10 }}>
        განახლება
      </Button>
      <Table stickyHeader>
        <TableHead style={{ background: theme.palette.neutralLighterAlt }}>
          <TableRow>
            <TableCell>სახელი</TableCell>
            <TableCell>გვარი</TableCell>
            <TableCell>სტატუსი</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.map((courier, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell>
                  <a
                    href={`/courier-salary/${courier.userId}/${courier.firstName} ${courier.lastName}`}
                    target='_blank'
                    rel='noreferrer'>
                    {courier.firstName}
                  </a>
                </TableCell>
                <TableCell>{courier.lastName}</TableCell>
                <TableCell>{courier.activeStatus ? 'აქტიური' : 'არა აქტიური'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
