import React, { useEffect, useState, useRef } from 'react';
import { Sidebar, sidebarWidth } from 'components';
import { Route, Switch } from 'react-router-dom';
import { navigation } from 'constants/index';
import { makeStyles } from '@material-ui/core/styles';
import { Deliveries } from 'containers';
import { CourierOrderHistory } from '../CourierOrderHistory/CourierOrderHistory';
import { Earned } from '../Earned/Earned';
import { CurrentOrder } from '../CurrentOrder/CurrentOrder';
import { toast } from 'react-toastify';
import { theme } from 'data';
import ring from 'assets/ring.mp3';
import { useDispatch, useSelector } from 'react-redux';
import { CourierService, OrderService } from 'services';
import { useMemo } from 'react';

const useStyles = makeStyles((props) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: `${sidebarWidth}px calc(100% - ${sidebarWidth}px)`,
    background: theme.palette.white,
    '@media (max-width: 768px)': {
      gridTemplateColumns: `100%`
    }
  },
  content: {
    padding: '4em',
    '@media (max-width: 768px)': {
      padding: `.5em`,
      paddingTop: '5em'
    }
  }
}));

export const Courier = () => {
  const classes = useStyles();
  const locationWatcher = useRef(null);
  const wakeLock = useRef(null);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const latestOrderId = useSelector((state) => state.orders.latestUnapprovedOrder);
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const courierService = useMemo(() => new CourierService());
  const latestOrderProp = useRef(null);
  latestOrderProp.current = latestOrderId;
  const ws = useRef({});

  const startWatch = async (id) => {
    if ('geolocation' in navigator) {
      if ('wakeLock' in navigator) {
        wakeLock.current = await navigator.wakeLock.request('screen').catch((err) => {
          alert(
            'ავტომატურად ეკრანის სინათლის რეჟიმზე დაყენება ვერ ხერხდება, დააყენეთ თქვენით მოწყობილობით პარამეტრებიდან.'
          );
        });
      }
      ws.current[id] = new WebSocket(`wss://maxdelivery.grena.ge/stream?id=${id}`);
      locationWatcher.current = navigator.geolocation.watchPosition(
        (p) => {
          console.log(ws.current[id]);
          if (ws.current[id].readyState === 1) {
            ws.current[id].send(
              JSON.stringify({
                lat: p.coords.latitude,
                lng: p.coords.longitude
              })
            );
          }
        },
        (err) => {
          toast.error(`დაფიქსირდა შეცდომა ${err.message}`, {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        },
        {
          timeout: 1 * 60 * 1000,
          enableHighAccuracy: true,
          maximumAge: 0
        }
      );
    } else {
      alert('თქვენი მოწყობილობით ლოკაცია ვერ გაზიარდება');
    }
  };

  const endWatcher = async (trackerId) => {
    if (ws.current[trackerId]) {
      ws.current[trackerId].close();
    }
    if (locationWatcher.current) {
      navigator.geolocation.clearWatch(locationWatcher.current);
      locationWatcher.current = null;
    }
    if (wakeLock.current) {
      await wakeLock.current.release();
      wakeLock.current = null;
    }
  };

  useEffect(() => {
    return async () => {
      if (locationWatcher.current) {
        navigator.geolocation.clearWatch(locationWatcher.current);
        locationWatcher.current = null;
      }
      if (wakeLock.current) {
        await wakeLock.current.release();
        wakeLock.current = null;
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      await courierService.changeStatus(true);
    })();
  }, []);

  useEffect(() => {
    const onFocus = async () => {
      await courierService.changeStatus(true);
    };

    const onBlur = async () => {
      await courierService.changeStatus(false);
    };

    const onBeforeUnload = async () => {
      try {
        fetch('https://maxdelivery.grena.ge/api/Account/ChangeUserActiveStatus', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            status: false
          }),
          keepalive: true
        });
      } catch (error) {
        console.log(error);
      }
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const orderService = new OrderService();
      const res = await orderService.getOrders({ statusCodes: [5], orderType: 'DELIVERY' });
      const latestUnapprovedOrder = res
        .slice()
        .sort((a, b) => new Date(a.recordTime).getDate() - new Date(b.recordTime).getDate())
        .find((e) => e.statusCode.id === 5);
      if (latestUnapprovedOrder && latestUnapprovedOrder?.orderId !== latestOrderProp.current) {
        new Audio(ring).play();
        dispatch({
          type: 'SET_LATEST_ORDER',
          payload: latestUnapprovedOrder?.orderId
        });
      }
      setOrders(res);
    };
    fetch();
    const interval = setInterval(fetch, 30 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.container}>
      <Sidebar opened={false} sidebarType='courier' />
      <div className={classes.content}>
        <Switch>
          <Route exact path={navigation.courier.orders}>
            <Deliveries setOrders={setOrders} orders={orders} startWatch={startWatch} />
          </Route>
          <Route exact path={navigation.courier.orderHistory}>
            <CourierOrderHistory />
          </Route>
          <Route exact path={navigation.courier.earned}>
            <Earned />
          </Route>
          <Route exact path={navigation.courier.currentOrder}>
            <CurrentOrder endWatcher={endWatcher} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
