import styled from 'styled-components';
const textareaStatusBorderHelper = {
  error: (opacity = 1) => `1px solid rgb(255,0,0,${opacity})`,
  success: (opacity = 1) => `1px solid rgb(0,168,82,${opacity})`
};

export const TextareaContainer = styled.div`
  width: 100%;
`;

export const TextareaDesc = styled.div`
  font-size: 0.9em;
  margin-bottom: 10px;
  font-weight: 600;
`;

export const TextareaWrapper = styled.div`
  border: ${(props) => (props.error ? '1px solid red' : '1px solid lightgrey')};
  background-color: 'transparent';
  border-radius: 5px;
  font-size: 1em;
  transition-duration: 0.15s;
  &:focus-within {
    ${(props) =>
      `
      border: 1px solid ${props.error ? textareaStatusBorderHelper['error']() : 'black'};
      box-shadow: ${
        props.error ? `0px 0px 4px ${textareaStatusBorderHelper['error'](0.5)}` : 'none'
      };
    `};
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  outline: none;
  color: black;
  padding: 17px 10px;
  resize: none;
  border: none;
  height: 80px;
  cursor: text;
  font-size: 1.1em;
  background-color: transparent;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #bfc4cc;
    font-size: 1em;
  }
  :-ms-input-placeholder {
    color: #bfc4cc;
    font-size: 1em;
  }
  @media (max-width: 768px) {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #bfc4cc;
      font-size: 0.9em;
    }
    :-ms-input-placeholder {
      color: #bfc4cc;
      font-size: 0.88m;
    }
  }
`;

export const Error = styled.p`
  color: red;
  font-size: 0.8em;
  margin-top: 7px;
  display: ${(props) => (props.visible ? 'initial' : 'none')};
  @media (max-width: 320px) {
    font-size: 0.7em;
  }
`;
