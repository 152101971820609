import { GET_INGREDIENTS } from "./types";

const initialState = {
    ingredients: []
};

export const ingredients = (state = initialState, action) => {
    switch(action.type){
        case GET_INGREDIENTS:
            return {
                ...state,
                ingredients: action.payload
            };
        default: return state;
    };
};