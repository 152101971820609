import {
  Button,
  Dialog,
  DialogContentText,
  IconButton,
  TableCell,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { Create, Delete } from '@material-ui/icons';
import { useState } from 'react';
import { EmployeeService } from 'services/EmployeeService';
import { EmployeeModal } from 'components';
import { useSelector } from 'react-redux';
import { Roles } from 'data';

export const Employee = ({ employee, refresh }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const employeeService = new EmployeeService();
  const roles = useSelector((state) => state.roles.roles);
  const { role } = useSelector((state) => state.user);

  const deleteHandler = async () => {
    const res = await employeeService.deleteEmployee({ ...employee, roleId: roles[0].id });
    if (res.ok) {
      setIsDeleted(true);
    }
  };

  if (isDeleted) {
    return null;
  }

  return (
    <>
      <TableRow>
        <TableCell>{employee.firstname}</TableCell>
        <TableCell>{employee.lastname}</TableCell>
        <TableCell>{employee.role}</TableCell>
        <TableCell>{employee.phoneNumber}</TableCell>
        <TableCell>{employee.branch}</TableCell>
        <TableCell>
          <IconButton onClick={() => setIsEditing(true)} disabled={role !== Roles.admin}>
            <Create />
          </IconButton>
          <IconButton onClick={() => setDeleteModal(true)} disabled={role !== Roles.admin}>
            <Delete style={{ fill: 'red' }} />
          </IconButton>
        </TableCell>
      </TableRow>

      <Dialog
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'თანამშრომლის წაშლა'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            დარწმუნებული ხართ რომ თანამშრომელი {employee.firstname} {employee.lastname}ს წაშლა
            გსურთ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModal(false)} color='primary'>
            გაუქმება
          </Button>
          <Button style={{ color: 'red' }} onClick={deleteHandler}>
            დიახ
          </Button>
        </DialogActions>
      </Dialog>
      <EmployeeModal
        preData={employee}
        closeHandler={() => setIsEditing(false)}
        isOpen={isEditing}
        onProceed={async (data) => {
          await employeeService.updateEmployee(data);
          setIsEditing(false);
          refresh();
        }}
      />
    </>
  );
};
