import styled from 'styled-components';
import bg from '../../../assets/banner.png'

export const Main = styled.main``;

export const Banner = styled.div`
  height: 700px;
  max-height: 85vh;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: 100%;
  @media (max-width: 768px) {
    background-size: cover;
    height: 80vh;
    max-height: unset;
  }
`;

export const BannerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const TitleContainer = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

export const Title = styled.p`
  font-size: 3.8em;
  font-weight: bold;
  color: white;
  @media (max-width: 768px) {
    font-size: 2.7em;
  }
`;

export const AddressInput = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1px 6px;
  border-radius: 40px;
  justify-content: space-around;
`;

export const AddressInputIcon = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  margin-left: 3px;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const AddressContainer = styled.div`
  width: 500px;
  position: relative;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const AddressInputInput = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  font-size: 1em;
  padding: 20px 5px;
  max-width: 80%;
  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 20px 10px;
  }
`;

export const SearchButton = styled.div`
  background-color: red;
  width: 46px;
  height: 46px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  & > svg {
    width: 75%;
    height: 100%;
    margin-left: 3px;
  }
  @media (max-width: 768px) {
    width: 50px;
    height: 41px;
  }
`;

export const MaxInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  margin: 5% 0;
  @media (max-width: 768px) {
    flex-direction: column;
    & > * {
      margin: 25px 0;
    }
  }
`;

export const MaxInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MaxInfoItemImg = styled.div`
  margin: auto auto 15px auto;
  & > img {
    width: 154px;
    height: 154px;
    @media (max-width: 768px) {
      width: 120px;
      height: 120px;
    }
  }
`;

export const MaxInfoTexts = styled.div`
  width: 300px;
  text-align: center;
`;

export const MaxInfoItemTitle = styled.p`
  font-weight: bold;
  font-size: 1.7em;
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.4em;
  }
`;

export const MaxInfoItemText = styled.p`
  font-size: 1em;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const LinkToInfo = styled.div`
  color: red;
  font-weight: bold;
  cursor: pointer;
`;

export const Products = styled.div`
  margin: 10% 2%;
  @media (max-width: 768px) {
    margin: 10% 5%;
  }
`;

export const ProductTitleContainer = styled.div`
  display: inline-block;
`;

export const ProductTitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

export const ProductTitleBorder = styled.div`
  width: 40%;
  height: 3px;
  background-color: black;
  margin: 10px auto;
`;

export const ProductsTitle = styled.p`
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  display: inline-block;
`;

export const Slider = styled.div`
  position: relative;
`;

export const SliderItem = styled.div`
  border: 2px solid #e9e9e9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
  height: 300px;
`;

export const SliderItemImg = styled.div`
  width: 70%;
  height: 240px;
  margin-bottom: 15px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SliderItemText = styled.p`
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 5px;
`;

export const Taste = styled.div`
  background-color: #fff0ed;
  padding-bottom: 80px;
`;

export const TasteWrapper = styled.div`
  display: flex;
  padding: 45px;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FoodPhoto = styled.div`
  flex: 5;
  & > img {
    object-fit: cover;
    width: 100%;
    height: 70%;
  }
`;

export const TasteText = styled.div`
  flex: 4;
  margin-left: 65px;
  @media (max-width: 768px) {
    margin-left: unset;
    margin-top: 15px;
  }
`;

export const TasteTextTitle = styled.p`
  font-size: 2.8em;
  font-weight: bold;
  margin-bottom: 14px;
`;

export const TasteTextDesc = styled.p`
  font-weight: 600;
  font-size: 1.1em;
  width: 400px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: unset;
  }
`;

export const TasteBtn = styled.button`
  background-color: red;
  padding: 13px 15px;
  border-radius: 40px;
  color: white;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
`;

export const Arrow = styled.div`
  background: red;
  display: inline-block;
  position: absolute;
  top: 50%;
  width: 37px;
  height: 37px;
  color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  left: ${(props) => (props.left ? '-.9%' : '98.4%')};
  cursor: pointer;
  @media (max-width: 768px) {
    left: ${(props) => (props.left ? '-.9%' : '93%')};
  }
`;

export const AutocompleteResultContainer = styled.div`
  background-color: white;
  max-width: 100%;
  position: absolute;
  width: 95%;
  max-height: 200px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  margin-left: 2.5%;
  margin-top: 1px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const AutocompleteLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  & > img {
    width: 50px;
    height: 50px;
  }
`;

export const AutocompleteItem = styled.div`
  padding: 20px;
`;
