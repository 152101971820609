import axios from 'axios';
import querystring from 'querystring';
import { toast } from 'react-toastify';

export class UsersService {
  async getUsers(search, index) {
    if (!index) {
      return {
        data: []
      };
    }
    const query = querystring.stringify({
      text: search,
      PageNumber: index,
      PageSize: 20,
      IsBlocked: false
    });
    const res = await axios.get(`/Admin/GetUsers?${query}`);
    if (!res.data.length) {
      toast.info('მომხარებლები არ მოიძებნა', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    return res;
  }

  async getBlockedUsers(search, index) {
    if (!index) {
      return {
        data: []
      };
    }
    const query = querystring.stringify({
      text: search,
      PageNumber: index,
      PageSize: 20,
      IsBlocked: true
    });
    const res = await axios.get(`/Admin/GetUsers?${query}`);
    if (!res.data.length) {
      toast.info('მომხარებლები არ მოიძებნა', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    return res;
  }

  async blockUser(number) {
    const res = await axios.put(`/Admin/BlockUser?phoneNumber=${number}`);
    return res;
  }
  async unblockUser(number) {
    const res = await axios.put(`/Admin/unBlockUser?phoneNumber=${number}`);
    return res;
  }
}
