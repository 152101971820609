import { ManagerService } from 'services';
import { GET_ROLES } from './types';

export const getRoles = () => async (dispatch) => {
  let managerService = new ManagerService();
  let roles = await managerService.getRoles();
  dispatch({
    type: GET_ROLES,
    payload: roles
  });
};
