import { List, ListItem, TextField, Typography } from '@material-ui/core';
import { OrderHistoryItem } from 'components';
import { LoadButton } from 'components/LoadButton/LoadButton';
import { navigation } from 'constants/navigation';
import { Roles } from 'data';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { OrderService } from 'services/OrderService';

export const OrderTrackers = () => {
  const [orders, setOrders] = useState([]);
  const [index, setIndex] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const { branchId } = useSelector((state) => state.userDetails);
  const { role } = useSelector((state) => state.user);

  useEffect(() => {
    const orderService = new OrderService();
    const getOrders = async () => {
      const res = await orderService.getOrders({
        statusCodes: 7,
        branchId: role === Roles.admin ? undefined : branchId,
        searchingText: search
      });
      setSearchLoading(false);
      setOrders(() => [...res]);
    };

    typeof branchId !== 'undefined' && index && getOrders();
  }, [index, branchId, search]);

  return (
    <div>
      <div style={{ marginBottom: '2em', textAlign: 'center' }}>
        <Typography variant='h4'>კურიერების და მათი შეკვეთების ადგილმდებარეობა</Typography>
        <div style={{ marginTop: '2em', textAlign: 'center' }}>
          <TextField
            variant='outlined'
            value={searchInput}
            label='ძიება'
            onChange={(ev) => setSearchInput(ev.target.value)}
          />
          <LoadButton
            variant='contained'
            color='primary'
            onClick={() => {
              if (searchInput === search) {
                return;
              }
              setOrders([]);
              setSearch(searchInput);
              setSearchLoading(true);
            }}
            loading={searchLoading}>
            ძებნა
          </LoadButton>
        </div>
        <div style={{ marginTop: 40 }}>
          <List>
            {orders.map((val) => {
              return (
                <ListItem>
                  <Link to={navigation.main.tracker(val.trackerId)}>
                    შეკვეთა {val.code} - კურიერი {val.courier.firstname} {val.courier.lastname}{' '}
                    {val.courier.phoneNumber}
                  </Link>
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};
