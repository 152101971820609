import { createTheme } from '@material-ui/core';

export const lightTheme = {
  themePrimary: '#fe0000',
  themeLighterAlt: '#010307',
  themeLighter: '#020e1b',
  themeLight: '#041a32',
  themeTertiary: '#083463',
  themeSecondary: '#0c4d92',
  themeDarkAlt: '#1e64af',
  themeDark: '#3878bb',
  themeDarker: '#6396cd',
  neutralLighterAlt: '#f8f8f8',
  neutralLighter: '#f4f4f4',
  neutralLight: '#eaeaea',
  neutralQuaternaryAlt: '#dadada',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c8c8',
  neutralTertiary: '#a19f9d',
  neutralSecondary: '#605e5c',
  neutralSecondaryAlt: '#c2c2c2',
  neutralPrimaryAlt: '#3b3a39',
  neutralPrimary: '#323130',
  neutralDark: '#201f1e',
  black: '#000000',
  white: '#ffffff'
};

export const theme = createTheme({
  palette: lightTheme,
  overrides:{
    MuiInputBase: {
      input: {
        padding: '13px 14px !important'
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 14px) scale(1)'
      }
    },
    MuiButton:{
      root: {
        padding: '10px 16px'
      }
    },
    MuiNativeSelect:{
      select:{
        padding: '13px 14px !important'
      }
    }
  }
});
