import axios from 'axios';

export class ManagerService {
  async getIngredients() {
    let response = await axios.get('/api/Manager/GetIngredients');
    return response.data;
  }
  async getBranches() {
    let response = await axios.get('/Admin/GetBranches');
    return response.data;
  }

  async getMeasurementTypes() {
    let response = await axios.get('/Storage/GetMeasurments');
    return response.data;
  }

  async getRoles() {
    const res = await axios.get('/Admin/GetRoles');
    return res.data;
  }

  async getCategories() {
    const res = await axios.get('/api/Manager/GetCategories');
    return res.data;
  }

  async getStatuses() {
    const res = await axios.get('/api/Order/GetOrderStatuses');
    return res.data;
  }
}
