import { CourierService } from 'services';
import { GET_DELIVERIES } from './types';

export const getDeliveries = () => async (dispatch) => {
  const courierService = new CourierService();
  let deliveries = await courierService.getDeliveries();
  dispatch({
    type: GET_DELIVERIES,
    payload: deliveries
  });
};
