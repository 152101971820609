import { ManagerService } from "services"
import { GET_BRANCHES } from "./types";

export const getBranches = () => async (dispatch) => {
    let managerService = new ManagerService();
    let branches = await managerService.getBranches();
    dispatch({
        type: GET_BRANCHES,
        payload: branches
    });
}