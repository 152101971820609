import { Button, TextField, useTheme } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_BRANCH } from 'redux/actions/userActions';
import { ReportsService } from 'services/ReportsService';
import './Profile.css';

export const Profile = () => {
  const [state, setState] = useState({
    firstName: 'ვაჟა',
    lastName: 'ჯალაღანია',
    role: 'მენეჯერი',
    number: '555 12 42 12'
  });

  const [cashierReport, setCashierReport] = useState();

  const reportsService = new ReportsService();

  const userDetails = useSelector((state) => state.userDetails);
  
  const { role, branchId, id } = useSelector((state) => state.user);
  const branches = useSelector((state) => state.branches.branches);
  const dispatch = useDispatch();

  const onChange = (ev) => {
    ev.persist();
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }));
  };

  const toggleBranch = (ev, newVal) => {
    dispatch({ type: TOGGLE_BRANCH, payload: newVal });
  };

  useEffect(() => {
    const fetchCashierReport = async () => {
      if (userDetails.role === 'Cashier' && id) {
        const data = await reportsService.getCashierReport({
          cashierId: id,
          firstDateTime: `${dayjs().format('YYYY-MM-DD')} 02:00`,
          lastDateTime: `${dayjs().add(1, 'day').format('YYYY-MM-DD')} 02:00`
        });

        setCashierReport(data);
      }
    };

    fetchCashierReport();
  }, [userDetails.role, id]);

  return (
    <div style={{ textAlign: 'center' }}>
      <ToggleButtonGroup
        color='primary'
        exclusive
        style={{ marginBottom: '2em' }}
        value={branchId ?? userDetails.branchId}
        onChange={toggleBranch}>
        {branches.map((val) => {
          return (
            <ToggleButton key={val.id} value={val.id}>
              {val.addres}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <form className='profile-form'>
        <TextField
          variant='outlined'
          label='სახელი'
          value={userDetails.firstName}
          onChange={onChange}
          name='firstName'
          disabled
        />
        <TextField
          variant='outlined'
          label='გვარი'
          value={userDetails.lastName}
          onChange={onChange}
          name='lastName'
          disabled
        />
        <TextField
          variant='outlined'
          label='ნომერი'
          value={userDetails.phoneNumber}
          onChange={onChange}
          disabled
          name='number'
        />
        <TextField
          variant='outlined'
          label='როლი'
          value={role}
          onChange={onChange}
          name='role'
          disabled
        />
        {/*<Button
          variant='contained'
          style={{ background: theme.palette.themePrimary, color: theme.palette.white }}>
          შეცვლა
        </Button>*/}
      </form>
      {typeof cashierReport?.money === 'number' ? (
        <div className='cashier-report'>
          <p>თანხა: {cashierReport.money}₾</p>
          <p>შეკვეთები: {cashierReport.ordersAmount}</p>
        </div>
      ) : null}
    </div>
  );
};
