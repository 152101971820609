import React from 'react';
import { Sidebar, sidebarWidth } from 'components';
import { Route, Switch } from 'react-router-dom';
import { navigation } from 'constants/index';
import {
  Users,
  Employees,
  Storage,
  Reports,
  Products,
  BlockedUsers,
  Profile,
  OrderHistory,
  ActiveCouriers,
  PendingOrders
} from 'containers';
import { makeStyles } from '@material-ui/core/styles';
import { Orders } from '../orders/Orders';
import { Categories } from 'components/Categories/Categories';
import { Branches } from 'components/Branches/Branches';
import { Measurements } from 'components/Measurements/Measurements';
import { Ongoings } from '../Ongoings/Ongoings';
import { OrderTrackers } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { OrderService } from 'services';
import { Roles } from 'data';
import { useRef } from 'react';
import ring from 'assets/ring.mp3';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: `${sidebarWidth}px calc(100% - ${sidebarWidth}px)`,
    background: theme.palette.white
  },
  content: {
    padding: '4em'
  }
}));

export const Admin = () => {
  const classes = useStyles();
  const orderService = new OrderService();
  const { statuses } = useSelector((state) => state.products);
  const { role } = useSelector((state) => state.user);
  const latestOrderId = useSelector((state) => state.orders.latestUnapprovedOrder);
  const { branchId } = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const latestOrderProp = useRef(null);
  latestOrderProp.current = latestOrderId;

  useEffect(() => {
    const fetch = async () => {
      const res = await orderService.getOrders({
        PageNumber: 1,
        PageSize: 10,
        statusCodes: statuses?.filter((val) => val.id !== 8).map((val) => val.id),
        branchId: role === Roles.admin ? undefined : branchId
      });
      const latestUnapprovedOrder = res
        .slice()
        .sort((a, b) => new Date(a.recordTime).getDate() - new Date(b.recordTime).getDate())
        .find((e) => e.statusCode.id === 2);
      if (latestUnapprovedOrder && latestUnapprovedOrder?.orderId !== latestOrderProp.current) {
        new Audio(ring).play();
        dispatch({
          type: 'SET_LATEST_ORDER',
          payload: latestUnapprovedOrder?.orderId
        });
      }
    };
    fetch();
    const interval = setInterval(fetch, 30 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.container}>
      <Sidebar sidebarType='admin' />
      <div className={classes.content}>
        <Switch>
          <Route path={navigation.admin.employees}>
            <Employees />
          </Route>
          <Route exact path={navigation.admin.users}>
            <Users />
          </Route>
          <Route exact path={navigation.admin.storage}>
            <Storage />
          </Route>
          <Route exact path={navigation.admin.reports}>
            <Reports />
          </Route>
          <Route exact path={navigation.admin.products}>
            <Products />
          </Route>
          <Route exact path={navigation.admin.blockedUsers}>
            <BlockedUsers />
          </Route>
          <Route exact path={navigation.admin.profile}>
            <Profile />
          </Route>
          <Route exact path={navigation.admin.orders}>
            <Orders />
          </Route>
          <Route exact path={navigation.admin.pendings}>
            <PendingOrders />
          </Route>
          <Route exact path={navigation.admin.orderHistory}>
            <OrderHistory />
          </Route>
          <Route exact path={navigation.admin.onRoad}>
            <OrderTrackers />
          </Route>
          <Route exact path={navigation.admin.categories}>
            <Categories />
          </Route>
          <Route exact path={navigation.admin.branches}>
            <Branches />
          </Route>
          <Route exact path={navigation.admin.measurements}>
            <Measurements />
          </Route>
          <Route exact path={navigation.admin.ongoings}>
            <Ongoings />
          </Route>
          <Route exact path={navigation.admin.couriers}>
            <ActiveCouriers />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
