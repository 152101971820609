import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import { LoadButton } from 'components/LoadButton/LoadButton';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { StorageService } from 'services';

export const SendProduct = ({ product, currentBranchId, isOpen, closeHandler, getProducts }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    quantity: '',
    branchId: ''
  });

  const storageService = new StorageService();
  const { branches } = useSelector((state) => state.branches);

  useEffect(() => {
    if (branches.length) {
      setState((prevState) => ({
        ...prevState,
        branchId: branches[0].id
      }));
    }
  }, [branches]);

  const onChange = (ev) => {
    ev.persist();
    setState((prevState) => ({ ...prevState, [ev.target.name]: ev.target.value }));
  };

  return (
    <Dialog open={isOpen} onClose={closeHandler}>
      <DialogTitle>{'პროდუქტის გაგზავნა'}</DialogTitle>
      <DialogContent>
        <h2>{product.name}</h2>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '1em',
            width: '400px'
          }}>
          <TextField
            name={'quantity'}
            onChange={onChange}
            variant={'outlined'}
            label={'რაოდენობა'}
            value={state.quantity}
            type='number'
            endAdornment={product.measurmentType}
            inputProps={{
              min: 0,
              max: product.quantity
            }}
          />
          <FormControl variant='outlined'>
            <InputLabel>ფილიალი</InputLabel>
            <Select
              native
              label='ფილიალი'
              onChange={onChange}
              name={'branchId'}
              value={state.branchId}>
              {branches.map((val, idx) => {
                if (val.id === currentBranchId) {
                  return null;
                }

                return (
                  <option key={idx} value={val.id}>
                    {val.addres}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color='primary'>
          გაუქმება
        </Button>
        <LoadButton
          style={{ color: 'red' }}
          disabled={loading}
          loading={loading}
          onClick={async () => {
            const { quantity, branchId } = state;
            setLoading(true);
            await storageService.sendProduct({
              quantity,
              branchId,
              name: product.name,
              measurementTypeId: product.measurement.id
            });
            await getProducts(currentBranchId);
            setLoading(false);
            closeHandler();
          }}>
          {'გაგზავნა'}
        </LoadButton>
      </DialogActions>
    </Dialog>
  );
};
