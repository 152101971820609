import * as Styled from './Modal.styles';
import Modal from 'react-modal';
import { GrClose } from 'react-icons/gr';
import { HiOutlineMinus } from 'react-icons/hi';
import { HiOutlinePlus } from 'react-icons/hi';
import { ingredientTypes } from 'data';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'relative',
    border: 'none',
    padding: `0 0 20px 0`,
    borderRadius: 20,
    width: 450,
    overflowX: 'hidden',
    maxHeight: '98.5vh',
    maxWidth: '97%'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.7)'
  }
};

export const IngredientModal = ({
  data: { imagePath, ingredients, quantity, price },
  ...props
}) => {
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const handleIngredientTypeChange = (ingredient, action) => {
    let updatedIngredient;
    switch (action) {
      case 'increment': {
        updatedIngredient = {
          ...ingredient,
          type: ingredientTypes.find((e) => e.id === ingredient.type).next || ingredient.type
        };
        break;
      }
      case 'decrement': {
        updatedIngredient = {
          ...ingredient,
          type: ingredientTypes.find((e) => e.id === ingredient.type).previous || ingredient.type
        };
        break;
      }
      default:
        break;
    }
    if (updatedIngredient.type !== ingredient.type) {
      props.handleIngredientTypeChange(updatedIngredient);
    }
  };
  return (
    <Modal
      style={customStyles}
      onRequestClose={props.close}
      isOpen={props.isOpen}
      shouldCloseOnOverlayClick>
      <Styled.Close>
        <GrClose onClick={props.close} />
      </Styled.Close>
      <Styled.ImageContainer>
        <img src={imagePath} />
      </Styled.ImageContainer>
      <Styled.Content>
        <Styled.IngredientsContanier>
          {ingredients?.map((val) => {
            const ingredientType = ingredientTypes.find((e) => e.id === val.type);
            return (
              <Styled.Ingredient>
                <Styled.QuantityButton
                  onClick={(e) => handleIngredientTypeChange(val, 'decrement')}>
                  <HiOutlineMinus />
                </Styled.QuantityButton>
                <Styled.IngredientName
                  background={
                    (!isLoggedIn && 'transparent') ||
                    (ingredientType.id === 4 && 'green') ||
                    (ingredientType.id === 2 && 'red') ||
                    (ingredientType.id === 1 && 'red')
                  }>
                  {val.name} - {ingredientType.name}
                </Styled.IngredientName>
                <Styled.QuantityButton
                  onClick={(e) => handleIngredientTypeChange(val, 'increment')}>
                  <HiOutlinePlus />
                </Styled.QuantityButton>
              </Styled.Ingredient>
            );
          })}
        </Styled.IngredientsContanier>
        <Styled.Quantity>
          <Styled.QuantityButton onClick={(e) => props.handleQuantityChange(quantity - 1)}>
            <HiOutlineMinus />
          </Styled.QuantityButton>
          <TextField
            onChange={(e) => props.handleQuantityChange(e.target.value)}
            value={quantity}
            style={{ width: '100%', margin: '0 5%', flex: 1, paddingBottom: 2 }}
            variant='standard'
            label='რაოდენობა'
            type='number'
            inputProps={{
              min: 1
            }}
          />
          <Styled.QuantityButton onClick={(e) => props.handleQuantityChange(quantity + 1)}>
            <HiOutlinePlus />
          </Styled.QuantityButton>
        </Styled.Quantity>
        <Styled.AddButton onClick={() => props.handleAddToCart(!!props.isEditMode)}>
          დამატება <Styled.BtnText>₾{(price * quantity).toFixed(2)}</Styled.BtnText>
        </Styled.AddButton>
      </Styled.Content>
    </Modal>
  );
};
