import styled from 'styled-components';

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  position: absolute;
  width: 91%;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  & > svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const AddButton = styled.button`
  width: 80%;
  padding: 16px;
  margin: 50px auto auto auto;
  background-color: #00a852;
  border-radius: 7px;
  color: white;
  font-size: 1.08em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  font-weight: 500;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > svg {
    margin-left: 10px;
    font-size: 18px;
  }
  @media (max-height: 795px) {
    margin-top: 35px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 225px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-height: 795px) {
    height: 170px;
  }
`;

export const IngredientsContanier = styled.div`
  margin-top: 15px;
`;

export const Ingredient = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  @media (max-height: 795px) {
    margin: 24px 0;
  }
`;

export const QuantityButton = styled.div`
  background-color: #ddf2ff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const Content = styled.div`
  padding: 20px 20px 0 20px;
`;

export const IngredientName = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid black;
  background: ${(props) => props.background ?? 'transparent'};
  padding-bottom: 5px;
  font-weight: 500;
  margin: 0 20px;
  font-size: 0.95em;
  flex: 1;
  user-select: none;
  @media (max-height: 795px) {
    font-size: 0.9em;
  }
`;

export const Quantity = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 40px;
  @media (max-height: 795px) {
    margin-top: 32px;
  }
`;

export const BtnText = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
