import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
  @media (max-width: 320px) {
    margin-bottom: 2px;
  }
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: ${(props) => (props.checked ? '#00a852' : 'white')};
  border-radius: 3px;
  transition: all 50ms;
  font-family: sans-serif;
  z-index: -1;
  border: ${(props) => (props.checked ? '1px solid transparent' : '1px solid black')};
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
  &:hover {
    background-color: ${(props) => props.checked && '#2088FF'};
  }
  @media (max-width: 320px) {
    width: 14px;
    height: 14px;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  cursor: pointer;
`;

export const LabelText = styled.span`
  display: inline-block;
  margin-left: 6px;
`;
