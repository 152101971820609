import { Route, Redirect } from 'react-router-dom';

export const AccessRoute = ({ children, access, path, redirect, onlyChildren = false }) => {
  if (!access) {
    return <Redirect to={redirect} />;
  }

  if (onlyChildren) {
    return children;
  }

  return (
    <Route path={path} exact>
      {children}
    </Route>
  );
};
