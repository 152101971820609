const bases = {
  admin: '/admin',
  courier: '/courier',
  main: '/'
};

export const navigation = {
  main: {
    homepage: `${bases.main}`,
    products: `${bases.main}products`,
    tracker: (id) => `${bases.main}order-tracker/${id}`,
    tv: (id) => `/tv/${id}`
  },
  admin: {
    dashboard: `${bases.admin}`,
    ongoings: `${bases.admin}/ongoings`,
    pendings: `${bases.admin}/pending-ongoiongs`,
    login: `${bases.admin}/login`,
    storage: `${bases.admin}/storage`,
    employees: `${bases.admin}/employees`,
    onRoad: `${bases.admin}/on-road`,
    users: `${bases.admin}/users`,
    reports: `${bases.admin}/reports`,
    products: `${bases.admin}/products`,
    blockedUsers: `${bases.admin}/blockedUsers`,
    profile: `${bases.admin}/profile`,
    orders: `${bases.admin}/orders`,
    orderHistory: `${bases.admin}/orderHistory`,
    categories: `${bases.admin}/categories`,
    branches: `${bases.admin}/branches`,
    measurements: `${bases.admin}/measurements`,
    couriers: `${bases.admin}/couriers`,
    courierSalary: (courierId, name) => `/courier-salary/${courierId}/${name}`
  },
  courier: {
    dashboard: `${bases.courier}`,
    orders: `${bases.courier}/orders`,
    orderHistory: `${bases.courier}/orderHistory`,
    earned: `${bases.courier}/earned`,
    currentOrder: `${bases.courier}/currentOrder`
  }
};
