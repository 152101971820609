import React from 'react';
import * as Styled from './Receipt.styles';
import logo from 'assets/newmax.png';
import { FaFacebookSquare } from 'react-icons/fa';
import day from 'dayjs';
import { orderType } from 'constants/values';

const specifications = {
  მეტი: '+',
  ნაკლები: '-',
  გარეშე: 'გარეშე'
};

export const Receipt = React.forwardRef((props, ref) => {
  console.log(props);
  return (
    <style type='text/css' media='print'>
      <Styled.ReceiptContainer ref={ref}>
        <Styled.TopPart>
          <Styled.LogoContainer>
            <img style={{ width: '100%', height: '100%' }} src={logo} />
          </Styled.LogoContainer>
          <Styled.Dates>
            <Styled.Date>{day(props.recordTime).format('YYYY/MM/DD')}</Styled.Date>
            <Styled.Date>{day(props.recordTime).format('HH:mm:ss')}</Styled.Date>
          </Styled.Dates>
          <Styled.Code>#{props.code}</Styled.Code>
          <Styled.OrderDetailsWrapper
            style={{ borderBottom: '1px solid black', paddingBottom: 10 }}>
            <Styled.OrderDetails>
              {props.productList
                ?.sort((a, b) => a.price - b.price)
                ?.map((val, idx) => {
                  return (
                    <Styled.ProductWrapper>
                      <Styled.OrderDetail key={val.id}>
                        <Styled.OrderDetailItem>
                          {val?.quantity}ც {val?.name} {val.price}ლ
                        </Styled.OrderDetailItem>
                      </Styled.OrderDetail>
                      <Styled.Ingredinets
                        style={
                          val.specificationList.flatMap((val) => {
                            if (val.specification !== 'სტანდარტი') {
                              return `${val.ingredient.name}-${val.specification}`;
                            } else {
                              return [];
                            }
                          }).length > 0
                            ? {}
                            : {}
                        }>
                        {val.specificationList.flatMap((val) => {
                          if (val.specification !== 'სტანდარტი') {
                            return (
                              <p>
                                {val.ingredient.name}: {specifications[val.specification]}
                              </p>
                            );
                          } else {
                            return [];
                          }
                        })}
                      </Styled.Ingredinets>
                    </Styled.ProductWrapper>
                  );
                })}
            </Styled.OrderDetails>
          </Styled.OrderDetailsWrapper>
          <div style={{ padding: '0 10%', textAlign: 'center', marginBottom: 15 }}>
            <span style={{ fontSize: 24 }}>ჯამი:</span>{' '}
            <span style={{ fontSize: 24 }}>
              {props.price}ლ {props.orderType === 'DELIVERY' ? `+ მიტანის ${props.deliveryPrice}ლ` : ''}
            </span>
          </div>
          <div style={{ textAlign: 'center', fontSize: 16, marginBottom: 15 }}>
            <span>გადახდის ტიპი:</span>{' '}
            <span style={{ fontWeight: '600' }}>
              {props.paymentMethod === 'CASH'
                ? 'ნაღდი'
                : props.paymentMethod === 'CARD'
                ? 'ონლაინ გადახდა'
                : 'ტერმინალი'}
            </span>
          </div>
          {props.address?.userAddress && (
            <div style={{ textAlign: 'center', fontSize: 16, marginBottom: 15 }}>
              <span>მისამართი:</span>{' '}
              <span style={{ fontWeight: '600' }}>{props.address?.userAddress}</span>
            </div>
          )}
          {props.clientPhoneNumber && (
            <div style={{ textAlign: 'center', fontSize: 16, marginBottom: 15 }}>
              <span>ნომერი:</span>{' '}
              <span style={{ fontWeight: '600' }}>{props.clientPhoneNumber}</span>
            </div>
          )}
          <div style={{ textAlign: 'center', fontSize: 16, marginBottom: 15 }}>
            <span>წინასწარ გადახდა:</span>{' '}
            <span style={{ fontWeight: '600' }}>
              {props.paymentMethod === 'TERMINAL' ? 'კი' : 'არა'}
            </span>
          </div>
          <div style={{ textAlign: 'center', fontSize: 16, marginBottom: 15 }}>
            <span>შეკვეთის ტიპი:</span>{' '}
            <span style={{ fontWeight: '600' }}>
              {props.orderType === orderType.DELIVERY
                ? 'მიტანით'
                : props.orderType === orderType.TAKEAWAY
                ? 'წაღებით'
                : 'დარბაზში'}
            </span>
          </div>
          {props.description && (
            <div style={{ textAlign: 'center', fontSize: 16, marginBottom: 25 }}>
              <p>შენიშვნა:</p>
              <p style={{ fontSize: 14 }}>{props.description}</p>
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontSize: 29 }}>0322 47 47 74</p>
          </div>
        </Styled.TopPart>
        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <div style={{ textAlign: 'center' }}>
            <FaFacebookSquare style={{ transform: 'scale(1.4)', marginRight: 8 }} />
            <span>Max delivery / მაქს დელივერი</span>
          </div>
          <div style={{ fontSize: 20, textAlign: 'center' }}>გმადლობთ მობრძანებისთვის</div>
        </div>
      </Styled.ReceiptContainer>
    </style>
  );
});
