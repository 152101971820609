import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField
} from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { LoadButton } from 'components/LoadButton/LoadButton';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBranches } from 'redux/branches/actions';
import { getMeasurments } from 'redux/products/actions';
import { BranchesService, MeasurementService } from 'services';
import './Measurements.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: '0 15px'
  },
  listItem: {
    margin: '5px 0 !important'
  },
  blockBtn: {
    backgroundColor: 'red',
    color: 'white',
    marginLeft: '1em',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white'
    }
  }
}));

export const Measurements = () => {
  const { measurments } = useSelector((state) => state.products);
  const [newMeasurment, setNewMeasurment] = useState('');
  const [addLoading, setAddLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [editId, setEditId] = useState(undefined);
  const [newName, setNewName] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const measurementService = new MeasurementService();

  const addMeasurement = async () => {
    setAddLoading(true);
    await measurementService.addMeasurement(newMeasurment);
    await Promise.all([dispatch(getMeasurments())]);
    setNewMeasurment('');
    setAddLoading(false);
  };

  const deleteMeasurement = async (id) => {
    setDeleteId(id);
    await measurementService.deleteMeasurement(id);
    await Promise.all([dispatch(getMeasurments())]);
    setDeleteId(undefined);
  };

  const editBranch = async () => {
    await measurementService.editMeasurement(editId, newName);
    await Promise.all([dispatch(getMeasurments())]);
    setEditId(undefined);
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>საზომი ერთეულები</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField
          value={newMeasurment}
          onChange={(ev) => setNewMeasurment(ev.target.value)}
          variant='outlined'
          placeholder='ერთეული'
          style={{ marginRight: '1em' }}
        />
        <LoadButton
          color='secondary'
          variant='contained'
          disabled={addLoading || !newMeasurment}
          loading={addLoading}
          onClick={addMeasurement}>
          საზომი ერთეულის დამატება
        </LoadButton>
      </div>
      <List dense className={classes.root}>
        {measurments.map((val, idx) => {
          return (
            <React.Fragment>
              <ListItem className={classes.listItem} key={idx} button>
                {editId === val.id ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField value={newName} onChange={(ev) => setNewName(ev.target.value)} />
                    <Button color='secondary' variant='contained' onClick={editBranch}>
                      შენახვა
                    </Button>
                  </div>
                ) : (
                  <ListItemText id={val.id} primary={`${val.measurment}`} />
                )}
                <ListItemSecondaryAction>
                  <LoadButton
                    endIcon={<DeleteOutline />}
                    variant='contained'
                    className={classes.blockBtn}
                    size='small'
                    color='inherit'
                    onClick={() => deleteMeasurement(val.id)}
                    disabled={deleteId === val.id}
                    loading={deleteId === val.id}
                    disableElevation>
                    წაშლა
                  </LoadButton>
                  <Button
                    endIcon={<EditOutlined />}
                    variant='contained'
                    className={classes.blockBtn}
                    size='small'
                    color='primary'
                    disableElevation
                    onClick={() => {
                      if (val.id === editId) {
                        return setEditId(undefined);
                      }

                      setEditId(val.id);
                      setNewName(val.measurment);
                    }}>
                    რედაქტირება
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              {idx !== measurments.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );
};
