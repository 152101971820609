import { Button, makeStyles } from '@material-ui/core';
import { Cart, IngredientModal, Receipt, Product } from 'components';
import { sidebarWidth } from 'components/OrderCart/Cart';
import { DELIVERY_PRICE, ingredientTypes, Roles, roles, theme } from 'data';
import React from 'react';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getCategories, setProducts } from 'redux/products/actions';
import { OrderService } from 'services';
import { useReactToPrint } from 'react-to-print';
import groupBy from 'lodash.groupby';
import * as Styled from './Orders.styles';

const useStyles = makeStyles({
  page: {
    display: 'grid',
    gridTemplateColumns: `calc(100% - ${sidebarWidth}px) ${sidebarWidth}px`
  },
  forms: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 50%)`
  },
  orderFormContainer: {
    margin: '0 10px'
  }
});

export const Orders = () => {
  const defaultBranchId = useSelector((state) => state.userDetails.branchId);
  const receiptRef = useRef(null);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [order, setOrder] = useState({
    orderType: 'INSIDE',
    phone: '',
    address: '',
    branchId: defaultBranchId,
    orders: [],
    description: '',
    deliveryPrice: DELIVERY_PRICE,
    payType: '',
    additionalCheque: false
  });
  const [serverResponse, setServerResponse] = useState({});
  const { products, categories } = useSelector((state) => state.products);
  const [ingredientModal, setIngredientModal] = useState({
    isOpen: false,
    isEditMode: false,
    idx: null,
    data: {}
  });

  const sleep = (m) => new Promise((r) => setTimeout(r, m));

  const { id, role } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();

  const orderService = new OrderService();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(setProducts(0));
  }, [setProducts, dispatch]);

  const handleOrderItemChange = (key, value) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      [key]: value
    }));
  };

  const reactToPrintContent = React.useCallback(() => {
    return receiptRef.current;
  }, [receiptRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Receipt',
    onBeforeGetContent: () => void 0,
    onBeforePrint: () => void 0,
    removeAfterPrint: true
  });

  const placeOrder = async () => {
    const isCashier = role === roles[1];
    const res = await orderService.addOrder({
      ...order,
      id,
      branchId: isCashier ? defaultBranchId : order.branchId,
      orderType: order.orderType,
      statusId: isCashier ? 4 : 2
    });
    if (res) {
      let splitByCategory = [res.order];
      Object.values(groupBy(res.order.productList, 'categoryId')).map((val) => {
        splitByCategory.push({
          ...res.order,
          productList: val
        });
      });
      console.log(splitByCategory);
      setServerResponse(res.order);
      if (![Roles.operator, Roles.admin].includes(role)) {
        handlePrint();
        setTimeout(() => handlePrint(), 2000);
      }
      if (!order.additionalCheque) {
        setTimeout(() => setServerResponse({}), 3500);
      }
      if (order.additionalCheque) {
        setServerResponse(res.order);
        if (![Roles.operator, Roles.admin].includes(role)) {
          setTimeout(() => handlePrint(), 3000);
        }
        setTimeout(() => setServerResponse({}), 3500);
      }
      setOrder((prevState) => ({
        ...prevState,
        description: '',
        isDelivery: false,
        phone: '',
        address: '',
        branchId: isCashier ? defaultBranchId : order.branchId,
        comment: '',
        orders: [],
        orderType: 'INSIDE',
        deliveryPrice: DELIVERY_PRICE,
        payType: '',
        additionalCheque: false
      }));
    }
    toast.success('შეკვეთა წარმატებით შეიქმნა', {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const handleIngredientTypeChange = (ingredient) => {
    setIngredientModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        ingredients: prev.data.ingredients.map((val) => {
          if (val.id === ingredient.id) {
            return ingredient;
          }
          return val;
        })
      }
    }));
  };

  const handleQuantityChange = (quantity) => {
    if (quantity >= 1) {
      setIngredientModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          quantity: parseInt(quantity)
        }
      }));
    }
  };

  const handleAddToCart = () => {
    let product = { ...ingredientModal.data };
    setOrder((prev) => {
      let items = [...prev.orders];
      let productIndex = items.findIndex(
        (e) =>
          e.id === product.id &&
          JSON.stringify(e.ingredients) === JSON.stringify(product.ingredients)
      );
      productIndex =
        productIndex === -1 && ingredientModal.isEditMode ? ingredientModal.idx : productIndex;
      if (productIndex === -1) {
        return {
          ...prev,
          orders: [...items, product]
        };
      }
      items.splice(productIndex, 1, {
        ...items[productIndex],
        ingredients: product.ingredients,
        quantity:
          ingredientModal.isEditMode && ingredientModal.idx === productIndex
            ? product.quantity
            : items[productIndex].quantity + product.quantity
      });
      if (ingredientModal.isEditMode && ingredientModal.idx !== productIndex) {
        items = items.filter((_, idx) => idx !== ingredientModal.idx);
      }
      return {
        ...prev,
        orders: items
      };
    });
    setIngredientModal({
      isOpen: false,
      data: {}
    });
  };

  const setProductToChoose = (product, isEditMode, idx) => {
    product = {
      ...product,
      ingredients: product.ingredients.map((val) => {
        return {
          ...val,
          type: val.type || ingredientTypes[2].id
        };
      }),
      quantity: product.quantity || 1
    };
    setIngredientModal({
      isOpen: true,
      isEditMode: !!isEditMode,
      idx: idx,
      data: product
    });
  };

  const addCategoryFilter = (category) => {
    if (categoryFilter === category) {
      setCategoryFilter('');
    } else {
      setCategoryFilter(category);
    }
  };

  const handleCartRemove = (idx) => {
    setOrder((prev) => {
      return {
        ...prev,
        orders: prev.orders.filter((e, i) => i !== idx)
      };
    });
  };

  const toggleAdditionalCheque = () => {
    setOrder((prevState) => ({
      ...prevState,
      additionalCheque: !prevState.additionalCheque
    }));
  };

  const handleOrdersItemChange = (idx, value) => {
    setOrder((prev) => {
      let newOrders = prev.orders.map((val, i) => {
        if (i === idx) {
          return {
            ...val,
            quantity: parseInt(value)
          };
        }
        return val;
      });
      return {
        ...prev,
        orders: newOrders
      };
    });
  };

  const togglePayType = (ev) => {
    ev.persist();
    setOrder((prevState) => ({
      ...prevState,
      payType: ev.target.value
    }));
  };

  useEffect(() => {
    // !WARNING: HARDCODED
    if (categories.length) {
      setCategoryFilter(categories[0].id);
    }
  }, [categories]);

  const filteredProducts = products
    .filter((val) => {
      if (!categoryFilter) {
        return true;
      }
      return val.categoryId === categoryFilter;
    })
    .sort((a, b) => a.price - b.price);

  return (
    <div className={classes.page}>
      <div>
        <div>
          <Styled.Categories>
            {categories.map((val) => {
              return (
                <Styled.Category
                  onClick={() => addCategoryFilter(val.id)}
                  active={categoryFilter === val.id}>
                  {val.name}
                </Styled.Category>
              );
            })}
          </Styled.Categories>
        </div>
        <Styled.Products>
          <Styled.ProductsList>
            {filteredProducts.map((val) => {
              return <Product onClick={(e) => setProductToChoose(val)} {...val} />;
            })}
          </Styled.ProductsList>
        </Styled.Products>
      </div>
      <Cart
        handleOrdersItemChange={handleOrdersItemChange}
        handleOrderItemChange={handleOrderItemChange}
        handleEdit={(val, idx) => setProductToChoose(val, true, idx)}
        handleCartRemove={handleCartRemove}
        togglePayType={togglePayType}
        placeOrder={placeOrder}
        order={order}
        toggleAdditionalCheque={toggleAdditionalCheque}
      />
      <IngredientModal
        {...ingredientModal}
        handleAddToCart={handleAddToCart}
        handleIngredientTypeChange={handleIngredientTypeChange}
        handleQuantityChange={handleQuantityChange}
        close={() => setIngredientModal((prevData) => ({ ...prevData, isOpen: false }))}
      />
      <Receipt ref={receiptRef} {...serverResponse} />
    </div>
  );
};
