import { combineReducers } from 'redux';
import { products } from './products/reducers';
import { branches } from './branches/reducers';
import { user } from './user/reducers';
import { ingredients } from './ingredients/reducers';
import { deliveries } from './deliveries/reducers';
import userReducer from './userReducer';
import { roles } from './roles/reducers';
import orderReducer from './orderReducer';

export default combineReducers({
  user: userReducer,
  products,
  branches,
  ingredients,
  userDetails: user,
  deliveries,
  roles,
  orders: orderReducer
});
