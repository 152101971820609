import styled from 'styled-components';

export const InputContanier = styled.div`
  margin: 25px 0;
  ${(props) => props.invisible && `display: none;`}
`;

export const Branches = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.error && `margin-bottom: 10px;`}
  flex-wrap: wrap;
`;

export const CheckboxContainer = styled.div`
  margin: 5px 8px;
`;

export const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: lightgrey;
`;

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  position: absolute;
  width: 90%;
  & > svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const BranchList = styled.div`
  margin-top: 20px;
  display: ${(props) => (props.invisible ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  margin-bottom: -10px;
  flex-wrap: wrap;
`;

export const OrderButton = styled.button`
  width: 80%;
  padding: 16px;
  margin: 35px auto auto auto;
  background-color: #00a852;
  border-radius: 7px;
  color: white;
  font-size: 1.08em;
  border: none;
  outline: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > svg {
    margin-left: 10px;
    font-size: 18px;
  }
`;

export const DetailsTitle = styled.p`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 0.97em;
`;

export const DetailsContainer = styled.div`
  ${(props) => props.invisible && `display: none;`}
`;

export const BranchesWrapper = styled.div``;

export const Explanation = styled.p`
  text-align: center;
  font-size: .8em;
  width: 80%;
  margin: 7px auto auto;
`;