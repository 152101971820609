import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { StorageService } from 'services/StorageService';
import { StorageProduct, SetProductModal } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Roles } from 'data';

const useStyles = makeStyles({
  searchbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em 0'
  }
});

export const Storage = () => {
  const [state, setState] = useState([]);
  const [productModalFunction, setProductModalFunction] = useState('');
  const [productModalActive, setProductModalActive] = useState(false);
  const { measurments } = useSelector((state) => state.products);
  const { branches } = useSelector((state) => state.branches);
  const [product, setProduct] = useState({
    id: null,
    name: '',
    code: '',
    measurementType: '',
    measurementId: '',
    quantity: '',
    branchId: ''
  });
  const [search, setSearch] = useState('');

  const classes = useStyles();

  const theme = useTheme();

  const storageService = useMemo(() => new StorageService(), []);

  const { role, branchId: userBranchId } = useSelector((state) => state.user);

  const [branchId, setBranchId] = useState('null');

  useEffect(() => {
    if (role !== Roles.admin) setBranchId(userBranchId);
  }, [role, userBranchId]);

  const getProducts = async (x) => {
    const res = await storageService.getProducts(x);
    setState(res);
  };

  useEffect(() => {
    if (measurments.length) {
      setProduct({
        ...product,
        measurementType: measurments[0].measurment,
        measurementId: measurments[0].id
      });
    }
  }, [measurments.length]);

  useEffect(() => {
    getProducts(branchId);
  }, [storageService, branchId]);

  const openEditModal = (product) => {
    setProduct(product);
    setProductModalFunction('update');
    setProductModalActive(true);
  };

  const handleChange = (event) => {
    event.persist();
    setProduct((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const onMeasurementChange = (id, val) => {
    setProduct({
      ...product,
      measurementType: val,
      measurementId: id
    });
  };

  const addProduct = async () => {
    await storageService.addProduct({
      ...product,
      measurmentTypeId: product.measurementId
    });
    getProducts(branchId);
    setProductModalActive(false);
  };
  const editProduct = async () => {
    await storageService.editProduct({
      ...product,
      measurmentTypeId: product.measurementId
    });
    getProducts(branchId);
    setProductModalActive(false);
  };

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <ToggleButtonGroup
          color='primary'
          style={{ marginBottom: '2em' }}
          value={branchId}
          onChange={(ev) => setBranchId(ev.target.value)}>
          {role === Roles.cashier ? null : (
            <ToggleButton value={'null'}>მთავარი საწყობი</ToggleButton>
          )}
          {branches.map((val) => {
            if (role === Roles.cashier && val.id !== userBranchId) {
              return null;
            }
            return (
              <ToggleButton key={val.id} value={`${val.id}`}>
                {val.addres} {val.city}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
      <div className={classes.searchbar}>
        <TextField
          startAdornment={<Search />}
          variant='outlined'
          label='ძიება'
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
        <Button
          variant='contained'
          onClick={(e) => {
            setProductModalActive(true);
            setProductModalFunction('add');
          }}
          style={{ background: theme.palette.themePrimary, color: theme.palette.white }}>
          დამატება
        </Button>
      </div>
      <Table>
        <TableHead style={{ background: theme.palette.neutralLighterAlt }}>
          <TableRow>
            <TableCell>სახელი</TableCell>
            <TableCell>საზომი ერთეული</TableCell>
            <TableCell>რაოდენობა</TableCell>
            <TableCell>რედაქტირება</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.map((product) => {
            if (!product.name.toLowerCase().includes(search.toLowerCase())) {
              return null;
            }

            return (
              <StorageProduct
                key={product.id}
                openEditModal={() =>
                  openEditModal({
                    ...product,
                    measurementType: product.measurement.measurment,
                    measurementId: product.measurement.id
                  })
                }
                isMain={branchId === 'null'}
                product={product}
                branchId={branchId}
                getProducts={getProducts}
              />
            );
          })}
        </TableBody>
      </Table>
      <SetProductModal
        handleChange={handleChange}
        productModalFunction={productModalFunction}
        product={product}
        open={productModalActive}
        setOpen={setProductModalActive}
        action={productModalFunction === 'update' ? editProduct : addProduct}
        onMeasurementChange={onMeasurementChange}
      />
    </div>
  );
};
