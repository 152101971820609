import axios from 'axios';
import { roles } from 'data';

const mockData = [
  {
    firstname: 'სოზარ',
    lastname: 'სუბარი',
    role: roles[0],
    phoneNumber: '551512155'
  },
  {
    firstname: 'სოზარ',
    lastname: 'სუბარი',
    role: roles[0],
    phoneNumber: '551512155'
  },
  {
    firstname: 'სოზარ',
    lastname: 'სუბარი',
    role: roles[0],
    phoneNumber: '551512155'
  },
  {
    firstname: 'სოზარ',
    lastname: 'სუბარი',
    role: roles[0],
    phoneNumber: '551512155'
  }
];

export class EmployeeService {
  async addEmployee(employeeData) {
    await axios.post('/Admin/PersonalRegistration', {
      firstname: employeeData.firstname,
      lastname: employeeData.lastname,
      branchId: employeeData.branchId,
      roleId: employeeData.roleId,
      imgURL: '#',
      password: employeeData.password,
      phoneNumber: employeeData.phoneNumber,
      city: employeeData.city
    });
  }

  async updateEmployee(employeeData) {
    await axios.put(`/api/Account/UpdateProfile?phoneNumber=${employeeData.phoneNumber}`, {
      firstname: employeeData.firstname,
      lastname: employeeData.lastname,
      branchId: employeeData.branchId,
      roleId: employeeData.roleId,
      imgURL: '#',
      password: employeeData.password,
      phoneNumber: employeeData.phoneNumber,
      city: employeeData.city
    });
  }

  async getEmployees(index) {
    const res = await axios.get('/Admin/GetPersonals');
    return res.data;
  }

  async deleteEmployee(employeeData) {
    return await axios.put(`/api/Account/UpdateProfile?phoneNumber=${employeeData.phoneNumber}`, {
      firstname: '',
      lastname: '',
      phoneNumber: '',
      branchId: 0,
      roleId: employeeData.roleId
    });
  }
}
