import { Input, Checkbox, Error, Textarea, FormattedInput, Radio } from 'components';
import * as Styled from './Modal.styles';
import Modal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './helpers';
import { orderType } from 'constants/values';
import { GrClose } from 'react-icons/gr';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getBranches } from 'redux/branches/actions';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useMode } from 'hooks';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { DELIVERY_PRICE } from 'data';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxWidth: '97%',
    maxHeight: '100vh'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.7)'
  }
};

const useStyles = makeStyles(() => ({
  toggleButton: {}
}));

export const FinalizeOrderModal = (props) => {
  const branches = useSelector((store) => store.branches.branches);
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [mode] = useMode();
  const { reset, setValue, handleSubmit, control, watch, clearErrors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema(mode)),
    defaultValues: {
      number: '',
      address: '',
      lat: null,
      lng: null,
      orderType: orderType.DELIVERY,
      branches: [],
      note: ''
    }
  });

  const watcher = watch(['orderType', 'lat', 'lng']);

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  useEffect(() => {
    const defaultAddress = localStorage.getItem('address')
      ? JSON.parse(localStorage.getItem('address'))
      : null;
    if (defaultAddress) {
      setValue('address', defaultAddress.address);
      setValue('lat', defaultAddress.lat);
      setValue('lng', defaultAddress.lng);
    }
  }, []);

  useEffect(() => {
    clearErrors();
  }, [watcher[0]]);

  useEffect(() => {
    if (mode === 'kiosk') {
      setValue('orderType', orderType.INSIDE);
    }
  }, [mode]);

  useEffect(() => {
    const queryData = qs.parse(location.search.slice(1));
    if (
      branches.length > 0 &&
      queryData.currentBranch &&
      branches.findIndex((e) => e.id === parseInt(queryData.currentBranch)) !== -1
    ) {
      setValue('branches', [parseInt(queryData.currentBranch)]);
    } else {
      if (branches.length > 0 && props.detailsOpen) {
        setValue(
          'branches',
          branches.slice(0, 1).map((val) => val.id)
        );
      }
    }
  }, [location, branches, props.detailsOpen]);

  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
  };

  const handleOrder = ({ lat, lng, address, ...rest }, onFinally) => {
    props.handleSubmit({ address: { lat, lng, address }, ...rest });
    onFinally();
  };

  const price = useMemo(() => {
    return props.products.reduce((total, current) => total + current.quantity * current.price, 0);
  }, [props.products]);

  return (
    <Modal
      style={customStyles}
      onRequestClose={props.close}
      isOpen={props.detailsOpen}
      shouldCloseOnOverlayClick>
      <Styled.Close>
        <GrClose onClick={props.close} />
      </Styled.Close>
      <Controller
        name='orderType'
        control={control}
        render={({ field }, fieldState) => {
          return (
            <Styled.BranchList invisible={mode === 'kiosk'}>
              <ToggleButtonGroup
                value={field.value}
                classes={{ root: classes.toggleButton }}
                exclusive
                onChange={(e, v) => setValue('orderType', v)}
                ref={field.ref}>
                {mode !== 'kiosk' && <ToggleButton value={orderType.DELIVERY}>მიტანა</ToggleButton>}
                <ToggleButton value={orderType.TAKEAWAY}>წაღებით</ToggleButton>
                <ToggleButton value={orderType.INSIDE}>დარბაზში</ToggleButton>
              </ToggleButtonGroup>
            </Styled.BranchList>
          );
        }}
      />
      <Styled.InputContanier invisible={mode === 'kiosk'}>
        <Controller
          name='number'
          control={control}
          render={({ field: { onBlur, ...field }, fieldState: { error } }) => (
            <FormattedInput
              onBlur={onBlur}
              onChange={handleChange}
              name
              title='ნომერი'
              format='### ### ###'
              placeholder='555 555 555'
              error={error}
              {...field}
            />
          )}
        />
      </Styled.InputContanier>
      <Styled.InputContanier
        invisible={
          mode === 'kiosk' || watcher[0] === orderType.INSIDE || watcher[0] === orderType.TAKEAWAY
        }>
        <Controller
          name='address'
          control={control}
          render={({ field: { onBlur, value, onChange, ...field }, fieldState: { error } }) => {
            return (
              <Input
                onBlur={onBlur}
                onChange={(e) => {
                  setValue('address', e.target.value);
                  if (watcher[1] || watcher[2]) {
                    setValue('lat', null);
                    setValue('lng', null);
                  }
                }}
                title='მისამართი'
                placeholder='მაგ. ცხინვალის 7.'
                error={error}
                value={value}
                {...field}
              />
            );
          }}
        />
      </Styled.InputContanier>
      <Styled.InputContanier>
        <Controller
          name='note'
          control={control}
          render={({ field: { onBlur, value, ...field }, fieldState: { error } }) => {
            return (
              <Textarea
                onBlur={onBlur}
                title='შეტყობინება/შენიშვნა'
                placeholder='კურიერს გამოაყოლეთ დამატებით 2 წიწაკა...'
                error={error}
                value={value}
                {...field}
              />
            );
          }}
        />
      </Styled.InputContanier>
      <Styled.DetailsContainer invisible={mode === 'kiosk'}>
        <Styled.DetailsTitle>სასურველი ფილიალი</Styled.DetailsTitle>
        <Controller
          name='branches'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Styled.BranchesWrapper>
                <Styled.Branches error={!!error}>
                  {branches.map((val) => {
                    const isSelected = field.value.includes(val.id);
                    return (
                      <Styled.CheckboxContainer>
                        <Radio
                          ref={field.ref}
                          onChange={(e) => {
                            setValue('branches', [val.id]);
                            if (error) {
                              clearErrors('branches');
                            }
                          }}
                          checked={isSelected}
                          labelWrap={val.addres}
                        />
                      </Styled.CheckboxContainer>
                    );
                  })}
                </Styled.Branches>
                {error && <Error>{error.message}</Error>}
              </Styled.BranchesWrapper>
            );
          }}
        />
      </Styled.DetailsContainer>
      <Styled.OrderButton type='submit' onClick={handleSubmit((data) => handleOrder(data, reset))}>
        შეკვეთა {price.toFixed(2)}₾ {watcher[0] === orderType.DELIVERY && `+ ${DELIVERY_PRICE}₾`}
      </Styled.OrderButton>
      {mode !== 'kiosk' && watcher[0] === orderType.DELIVERY && (
        <Styled.Explanation>
          ქ. გორის გარეთ მიტანის საფასურს ყოველ კილომეტრეზე ემატება 50 თეთრი
        </Styled.Explanation>
      )}
    </Modal>
  );
};
