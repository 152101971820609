import axios from 'axios';
export class StorageService {
  async getProducts(branchId) {
    let res;
    if (branchId === 'null') {
      res = await axios.get(`/Storage/GetStorageProductsByBranch?Id=0&page=0&accepted=true`);
    } else {
      res = await axios.get(`/Storage/GetStorageProductsByBranch?Id=${branchId}&page=0`);
    }

    return res.data;
  }

  async deleteProducts(id) {
    const res = await axios.delete(`/Storage/RemoveProductFromStorage?ProductId=${id}`);
    return res;
  }

  async addProduct(productData) {
    await axios.post('/Storage/AddProductInStorage', {
      measurementTypeId: Number(productData.measurmentTypeId),
      marketProduct: productData.name,
      quantity: Number(productData.quantity)
    });
  }

  async editProduct(productData) {
    await axios.put(`/Storage/UpdateStorageProduct?productId=${productData.id}`, {
      measurementTypeId: Number(productData.measurmentTypeId),
      quantity: Number(productData.quantity),
      marketProduct: productData.name
    });
  }

  async sendProduct(productData) {
    await axios.post(`/Storage/AddStorageProductInBranch`, {
      measurementTypeId: Number(productData.measurementTypeId),
      quantity: Number(productData.quantity),
      marketProduct: productData.name,
      branchId: Number(productData.branchId) ?? null
    });
  }

  async acceptStorageProduct(productId, status) {
    const res = await axios.put(
      `/Storage/AcceptStorageProduct?storageProductId=${productId}&accept=${status}`
    );
    return res;
  }
}
