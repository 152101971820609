export const orderType = {
  DELIVERY: 'DELIVERY',
  TAKEAWAY: 'TAKEAWAY',
  INSIDE: 'INSIDE'
};

export const workgingSchedule = {
  START_TIME: '08:00',
  END_TIME: '02:00'
};
