const initialState = {
    latestUnapprovedOrder: null
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LATEST_ORDER': {
        console.log(action.payload)
      return {
        latestUnapprovedOrder: action.payload
      };
    }
    default:
      return state;
  }
};

export default orderReducer;
