import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const EmployeeModal = ({ preData, closeHandler, onProceed, isOpen }) => {
  const roles = useSelector((state) => state.roles.roles);
  const branches = useSelector((state) => state.branches.branches);
  const [state, setState] = useState(
    preData
      ? { ...preData, password: '' }
      : {
          firstname: '',
          lastname: '',
          phoneNumber: '',
          roleId: '',
          password: '',
          branchId: '',
          city: ''
        }
  );

  const onChange = (evt) => {
    const { name, value } = evt.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeHandler}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'თანამშრომლის დამატება'}</DialogTitle>
        <DialogContent>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '1em' }}>
            <TextField
              name={'firstname'}
              onChange={onChange}
              variant={'outlined'}
              label={'სახელი'}
              value={state.firstname}
            />
            <TextField
              name={'lastname'}
              onChange={onChange}
              variant={'outlined'}
              label={'გვარი'}
              value={state.lastname}
            />
            <TextField
              name={'phoneNumber'}
              onChange={onChange}
              variant={'outlined'}
              label={'მობილური'}
              value={state.phoneNumber}
            />
            <TextField
              name={'city'}
              onChange={onChange}
              variant={'outlined'}
              label={'ლოკაციია'}
              value={state.city}
            />
            <TextField
              name={'password'}
              onChange={onChange}
              variant={'outlined'}
              label={'პაროლი'}
              type={'password'}
              value={state.password}
            />
            <FormControl>
              <InputLabel id='demo-simple-select-label'>როლი</InputLabel>
              <Select
                label={'როლი'}
                variant={'outlined'}
                value={state.roleId}
                onChange={(ev) => setState({ ...state, roleId: ev.target.value })}
                name='role'>
                {roles.map((role, idx) => {
                  return (
                    <MenuItem key={`${role.name}_${idx}`} value={role.id}>
                      {role.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='demo-simple-select-label'>ფილიალი</InputLabel>
              <Select
                label={'ფილიალი'}
                variant={'outlined'}
                value={state.branchId}
                onChange={(ev) => setState({ ...state, branchId: ev.target.value })}
                name='role'>
                {branches.map((branch, idx) => {
                  return (
                    <MenuItem key={`${branch.id}_${idx}`} value={branch.id}>
                      {branch.addres}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHandler} color='primary'>
            გაუქმება
          </Button>
          <Button style={{ color: 'red' }} onClick={() => onProceed(state)}>
            {preData ? 'შეცვლა' : 'დამატება'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
