import {
  Button,
  Dialog,
  DialogContentText,
  IconButton,
  TableCell,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { Create, Delete, FormatLineSpacingRounded, SendOutlined } from '@material-ui/icons';
import { Roles } from 'data';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { StorageService } from 'services';
import { SendProduct } from './SendProduct';

export const StorageProduct = ({ product, openEditModal, isMain, branchId, getProducts }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isAccepted, setIsAccepted] = useState(product.accepted || isMain);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [sendModal, setSendModal] = useState(false);
  const storageService = new StorageService();

  const { role } = useSelector((state) => state.user);

  const deleteHandler = async () => {
    const res = await storageService.deleteProducts(product.id);
    if (res.ok) {
      setIsDeleted(true);
    }
  };

  const acceptHandler = async (status) => {
    setAcceptLoading(true);
    const res = await storageService.acceptStorageProduct(product.id, status);
    if (res.ok) {
      status ? setIsAccepted(true) : setIsDeleted(true);
    }
    setAcceptLoading(false);
  };

  if (isDeleted) {
    return null;
  }

  return (
    <>
      <TableRow>
        <TableCell>{product.name}</TableCell>
        <TableCell>{product.measurement.measurment}</TableCell>
        <TableCell>{product.quantity}</TableCell>
        <TableCell>
          <IconButton onClick={() => openEditModal(product)}>
            <Create />
          </IconButton>
          <IconButton onClick={() => setDeleteModal(true)}>
            <Delete style={{ fill: 'red' }} />
          </IconButton>
          {isMain ? (
            <IconButton onClick={() => setSendModal(true)}>
              <SendOutlined />
            </IconButton>
          ) : null}
          <div>
            {!isAccepted ? (
              <>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => acceptHandler(true)}
                  disabled={acceptLoading}>
                  დადასტურება
                </Button>
                <Button
                  color='red'
                  variant='contained'
                  onClick={() => acceptHandler(false)}
                  disabled={acceptLoading}>
                  უარყოფა
                </Button>
              </>
            ) : null}
          </div>
        </TableCell>
      </TableRow>
      <Dialog open={deleteModal} onClose={() => setDeleteModal(false)}>
        <DialogTitle>{'პროდუქტის წაშლა'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            დარწმუნებული ხართ რომ პროდუქტის {product.name} წაშლა გსურთ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModal(false)} color='primary'>
            გაუქმება
          </Button>
          <Button style={{ color: 'red' }} onClick={deleteHandler}>
            დიახ
          </Button>
        </DialogActions>
      </Dialog>{' '}
      <SendProduct
        product={product}
        isOpen={sendModal}
        closeHandler={() => setSendModal(false)}
        currentBranchId={branchId}
        getProducts={getProducts}
      />
    </>
  );
};
