import axios from 'axios';

export class BranchesService {
  async addBranch(address, city) {
    const res = await axios.post(`/Admin/AddBranch?Address=${address}&city=${city}`);
    return res;
  }

  async editBranch(id, addres, city) {
    const res = await axios.put(`/Admin/UpdateBranch`, {
      id,
      addres,
      city
    });
    return res;
  }

  async deleteBranch(id) {
    const res = await axios.delete(`/Admin/RemoveBranch?branchId=${id}`);
    return res;
  }
}
