import styled from 'styled-components';

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  position: absolute;
  width: 91%;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  & > svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const ConfirmButton = styled.button`
  width: 80%;
  padding: 16px;
  margin: 50px auto auto auto;
  background-color: #00a852;
  border-radius: 7px;
  color: white;
  font-size: 1.08em;
  border: none;
  outline: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :disabled {
    cursor: default;
    background-color: gray;
  }
  & > svg {
    margin-left: 10px;
    font-size: 18px;
  }
`;

export const OptionList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionsHeader = styled.p`
  margin-bottom: 35px;
  margin-top: 30px;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
`;

export const OptionsWrapper = styled.div`
  .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    background: #00c90057;
  }
`;

export const Item = styled.div`
  font-size: 1.2em;
  display: flex;
  align-items: center;
  color: black;
  & > svg {
    margin-left: 10px;
    width: 25px;
    height: 25px;
    color: #00a852;
  }
`;
