const { GET_DELIVERIES } = require("./types");

const initialState = {
    deliveries: []
};

export const deliveries = (state = initialState, action) => {
    switch (action.type) {
        case GET_DELIVERIES:
            return {
                ...state,
                deliveries: action.payload
            };
        default: return state;
    }
}