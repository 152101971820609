import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField
} from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { LoadButton } from 'components/LoadButton/LoadButton';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from 'redux/products/actions';
import { CategoriesService } from 'services';
import './Categories.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: '0 15px'
  },
  listItem: {
    margin: '5px 0 !important'
  },
  blockBtn: {
    backgroundColor: 'red',
    color: 'white',
    marginLeft: '1em',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white'
    }
  }
}));

export const Categories = () => {
  const { categories } = useSelector((state) => state.products);
  const [newCat, setNewCat] = useState('');
  const [addLoading, setAddLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [editId, setEditId] = useState(undefined);
  const [newName, setNewName] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const categoryService = new CategoriesService();

  const addCategory = async () => {
    setAddLoading(true);
    await categoryService.addCategory(newCat);
    await Promise.all([dispatch(getCategories())]);
    setAddLoading(false);
    setNewCat('');
  };

  const deleteCategory = async (id) => {
    setDeleteId(id);
    await categoryService.deleteCategory(id);
    await Promise.all([dispatch(getCategories())]);
    setDeleteId(undefined);
  };

  const editCategory = async () => {
    await categoryService.editCategory(editId, newName);
    await Promise.all([dispatch(getCategories())]);
    setEditId(undefined);
  };

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>კატეგორიები</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <TextField
          value={newCat}
          onChange={(ev) => setNewCat(ev.target.value)}
          variant='outlined'
          placeholder='სახელი'
          style={{ marginRight: '1em' }}
        />
        <LoadButton
          color='secondary'
          variant='contained'
          disabled={addLoading || !newCat}
          loading={addLoading}
          onClick={addCategory}>
          კატეგორიის დამატება
        </LoadButton>
      </div>
      <List dense className={classes.root}>
        {categories.map((val, idx) => {
          return (
            <React.Fragment>
              <ListItem className={classes.listItem} key={idx} button>
                {editId === val.id ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField value={newName} onChange={(ev) => setNewName(ev.target.value)} />
                    <Button color='secondary' variant='contained' onClick={editCategory}>
                      შენახვა
                    </Button>
                  </div>
                ) : (
                  <ListItemText id={val.id} primary={`${val.name}`} />
                )}
                <ListItemSecondaryAction>
                  <LoadButton
                    endIcon={<DeleteOutline />}
                    variant='contained'
                    className={classes.blockBtn}
                    size='small'
                    color='inherit'
                    onClick={() => deleteCategory(val.id)}
                    disabled={deleteId === val.id}
                    loading={deleteId === val.id}
                    disableElevation>
                    წაშლა
                  </LoadButton>
                  <Button
                    endIcon={<EditOutlined />}
                    variant='contained'
                    className={classes.blockBtn}
                    size='small'
                    color='primary'
                    disableElevation
                    onClick={() => {
                      if (val.id === editId) {
                        return setEditId(undefined);
                      }

                      setEditId(val.id);
                      setNewName(val.name);
                    }}>
                    რედაქტირება
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              {idx !== categories.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
    </div>
  );
};
