import axios from 'axios';

export class CourierService {
  async getDeliveries(name, password) {
    const res = [
      {
        id: 1,
        address: 'სტალინის 1',
        branch: 'წმინდაწყალი',
        products: [
          {
            name: 'შაურმა დიდი',
            ingredients: [
              {
                name: 'წიწაკა',
                type: 'ბევრი'
              },
              {
                name: 'ხახვი',
                type: 'ცოტა'
              }
            ]
          },
          {
            name: 'შაურმა პატარა',

            ingredients: [
              {
                name: 'წიწაკა',
                type: 'ბევრი'
              },
              {
                name: 'ხახვი',
                type: 'ცოტა'
              }
            ]
          }
        ],
        recepient: {
          number: '551360130'
        },
        orderedAt: new Date().getTime()
      },
      {
        id: 2,
        address: 'სტალინის 1',
        branch: 'წმინდაწყალი',
        products: [
          {
            name: 'შაურმა დიდი',

            ingredients: [
              {
                name: 'წიწაკა',
                type: 'ბევრი'
              },
              {
                name: 'ხახვი',
                type: 'ცოტა'
              }
            ]
          }
        ],
        recepient: {
          number: '551360130'
        },
        orderedAt: new Date().getTime()
      },
      {
        id: 3,
        address: 'სტალინის 1',
        branch: 'წმინდაწყალი',
        products: [
          {
            name: 'შაურმა დიდი',

            ingredients: [
              {
                name: 'წიწაკა',
                type: 'ბევრი'
              },
              {
                name: 'ხახვი',
                type: 'ცოტა'
              }
            ]
          }
        ],
        recepient: {
          number: '551360130'
        },
        orderedAt: new Date().getTime()
      },
      {
        id: 4,
        address: 'სტალინის 1',
        branch: 'წმინდაწყალი',
        products: [
          {
            name: 'შაურმა დიდი',

            ingredients: [
              {
                name: 'წიწაკა',
                type: 'ბევრი'
              },
              {
                name: 'ხახვი',
                type: 'ცოტა'
              }
            ]
          }
        ],
        recepient: {
          number: '551360130'
        },
        orderedAt: new Date().getTime()
      },
      {
        id: 5,
        address: 'სტალინის 1',
        branch: 'წმინდაწყალი',
        products: [
          {
            name: 'შაურმა დიდი',

            ingredients: [
              {
                name: 'წიწაკა',
                type: 'ბევრი'
              },
              {
                name: 'ხახვი',
                type: 'ცოტა'
              }
            ]
          }
        ],
        recepient: {
          number: '551360130'
        },
        orderedAt: new Date().getTime()
      }
    ];
    return res;
  }
  async changeStatus(status) {
    await axios.post(
      '/api/Account/ChangeUserActiveStatus',
      {},
      {
        params: {
          status
        }
      }
    );
  }
  async getActiveCouriers() {
    const res = await axios.get('/api/Account/GetUsersWithActiveStatuses');
    return res.data;
  }
}
