export const roles = {
  0: 'Courier',
  1: 'Cashier',
  2: 'Cook',
  3: 'Manager',
  4: 'Operator',
  5: 'Administrator'
};

export const Roles = {
  cashier: 'Cashier',
  manager: 'Manager',
  admin: 'Admin',
  courier: 'Courier',
  administrator: 'Administrator',
  operator: 'Operator'
};
