import { GET_BRANCHES } from "./types";

const initialState = {
    branches: []
};

export const branches = (state = initialState, action) => {
    switch(action.type){
        case GET_BRANCHES:
            return {
                ...state,
                branches: action.payload
            };
        default: return state;
    };
};