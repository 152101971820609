import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { EmployeeService } from 'services/EmployeeService';
import { Employee, EmployeeModal } from 'components';
import './Employees.css';
import { useSelector } from 'react-redux';
import { Roles } from 'data';

export const Employees = () => {
  const [state, setState] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [addEmployee, setAddEmployee] = useState(false);
  const [search, setSearch] = useState('');

  const theme = useTheme();

  const employeeService = useMemo(() => new EmployeeService(), []);

  const { role } = useSelector((state) => state.user);

  useEffect(() => {
    const getEmployees = async () => {
      const res = await employeeService.getEmployees();
      setState(res);
    };

    getEmployees();
  }, [employeeService, refresh]);

  return (
    <div>
      <div className='searchbar'>
        <TextField
          startAdornment={<Search />}
          variant='outlined'
          label='ძიება'
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
        {role === Roles.admin ? (
          <Button
            variant='contained'
            onClick={() => setAddEmployee(true)}
            style={{ background: theme.palette.themePrimary, color: theme.palette.white }}>
            დამატება
          </Button>
        ) : null}
      </div>
      <Table stickyHeader>
        <TableHead style={{ background: theme.palette.neutralLighterAlt }}>
          <TableRow>
            <TableCell>სახელი</TableCell>
            <TableCell>გვარი</TableCell>
            <TableCell>სტატუსი</TableCell>
            <TableCell>მობილური</TableCell>
            <TableCell>ფილიალი</TableCell>
            <TableCell>რედაქტირება</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.map((employee, idx) => {
            if (
              !JSON.stringify(Object.values(employee)).toLowerCase().includes(search.toLowerCase())
            ) {
              return null;
            }

            return (
              <Employee
                key={`${employee.firstname}_${idx}`}
                employee={employee}
                refresh={() => setRefresh((r) => !r)}
              />
            );
          })}
        </TableBody>
      </Table>
      <EmployeeModal
        isOpen={addEmployee}
        closeHandler={() => {
          setAddEmployee(false);
        }}
        onProceed={async (data) => {
          await employeeService.addEmployee(data);
          setRefresh((p) => !p);
          setAddEmployee(false);
        }}
      />
    </div>
  );
};
