import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ReportsService } from 'services/ReportsService';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import './Reports.css';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import { FullReportTable, ReportTable } from 'components';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Roles } from 'data';
import { workgingSchedule } from '../../../constants';

export const Reports = () => {
  const [reportType, setReportType] = useState(0);
  const [reports, setReports] = useState([]);
  const { getFullReports, getReports } = new ReportsService();
  const { role } = useSelector((state) => state.user);
  const [dates, setDates] = useState({
    start: null,
    end: null,
    startTime: null,
    endTime: null
  });

  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState({
    min: null,
    max: null
  });

  const { branchId } = useSelector((state) => state.user);

  useEffect(() => {
    if (role === Roles.manager) {
      setOptions({
        min: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
        max: dayjs().format('YYYY-MM-DD')
      });
    }
  }, [role]);

  useEffect(() => {
    const fetchReports = async () => {
      if (dates.start && dates.end && dates.startTime && dates.endTime) {
        setLoading(true);
        const res = reportType
          ? await getFullReports({
              finishedOrderStatusCodeId: 8,
              firstDateTime: `${dates.start} ${dates.startTime}`,
              lastDateTime: `${dayjs(dates.end).format('YYYY-MM-DD')} ${dates.endTime}`,
              branchId
            })
          : await getReports({
              finishedOrderStatusCodeId: 8,
              firstDateTime: `${dates.start} ${dates.startTime}`,
              lastDateTime: `${dayjs(dates.end).format('YYYY-MM-DD')} ${dates.endTime}`,
              branchId: branchId
            });
        setLoading(false);
        setReports(reportType ? res : [res]);
      }
    };

    fetchReports();
  }, [reportType, getFullReports, getReports, dates]);

  const onTypeChange = (ev, newType) => {
    if (typeof newType !== 'number') {
      return null;
    }

    setReports([]);
    setReportType(newType);
  };

  const onDateChange = (ev) => {
    setDates({
      ...dates,
      [ev.target.name]: ev.target.value
    });
  };

  return (
    <div>
      <div style={{ textAlign: 'center', marginBottom: '2em' }}>
        <input
          type='date'
          name='start'
          onChange={onDateChange}
          value={dates.start}
          min={role === Roles.manager ? options.min : ''}
        />
        <input type='time' name='startTime' onChange={onDateChange} value={dates.startTime}></input>
        &nbsp;-&nbsp;
        <input type='date' name='end' onChange={onDateChange} value={dates.end} />
        <input type='time' name='endTime' onChange={onDateChange} value={dates.endTime}></input>
      </div>
      <div className='toggle-container'>
        <ToggleButtonGroup color='primary' value={reportType} exclusive onChange={onTypeChange}>
          <ToggleButton value={0}>ზოგადი</ToggleButton>
          <ToggleButton value={1}>დეტალური</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className='table-container'>
        <TableContainer component={Paper}>
          <Table>
            {reportType ? (
              <TableHead>
                <TableRow>
                  <TableCell>დასახელება</TableCell>
                  <TableCell>რაოდენობა</TableCell>
                  <TableCell>ფასი</TableCell>
                  <TableCell>ჯამი</TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow>
                  <TableCell>შემოსავალი</TableCell>
                  <TableCell>ქეში</TableCell>
                  <TableCell>ბარათი</TableCell>
                  <TableCell>ხარჯი</TableCell>
                  <TableCell>მოგება</TableCell>
                </TableRow>
              </TableHead>
            )}
            {reports.map((report, idx) => {
              return reportType ? (
                <FullReportTable report={report} />
              ) : (
                <ReportTable report={report} />
              );
            })}
          </Table>
        </TableContainer>
        <h3 style={{ textAlign: 'center', marginTop: '1.5em' }}>{loading ? 'იტვირთება...' : ''}</h3>
      </div>
    </div>
  );
};
