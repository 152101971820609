import styled from 'styled-components';

export const MapContainer = styled.div`
  width: 100%;
  height: 75vh;
  margin-top: auto;
  border-top: 1px solid black;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Details = styled.div`
  min-height: 25vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  @media (max-width: 768px) {
    display: initial;
    text-align: start;
    margin: 0 0px;
    width: 100%;
    min-height: unset;
    margin: 15px 0;
  }
`;

export const DetailsItem = styled.p`
  & > span {
    font-weight: 500;
  }
  margin: 5px 0;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  background: lightgrey;
  & > img{
    height: 412px;
    width: 412px;
    max-width: 95vw;
  }
`;