import axios from 'axios';
import { ingredientTypes } from 'data';
import qs from 'query-string';

export class OrderService {
  async getOrders(params) {
    const res = await axios.get(`/api/Order/GetOrders?${qs.stringify(params)}`);

    return res.data;
  }

  async getOrderIds(params) {
    const res = await axios.get(`/api/Order/GetOrderCodesByStatuses?${qs.stringify(params)}`);

    return res.data;
  }

  async takeOrder(orderId) {
    const res = await axios.post(
      `/api/Order/CourierConsentToOrder?${qs.stringify({ orderId, statusId: 7 })}`
    );

    return res.data;
  }

  async endOrder(orderId) {
    const res = await axios.post(
      `/api/Order/CourierConsentToOrder?${qs.stringify({ orderId, statusId: 8 })}`
    );

    return res.data;
  }

  async addOrder(data) {
    const res = await axios.post('/api/Order/CashierAddOrder', {
      clientPhoneNumber: data.phone,
      address: {
        lat: 0,
        lng: 0,
        userAddress: data.address
      },
      branchId: data.branchId,
      recipientUserId: data.id,
      orderType: data.orderType,
      description: data.description,
      statusId: data.statusId,
      deliveryPrice: data.deliveryPrice ? parseFloat(data.deliveryPrice) : null,
      productList: data.orders.map((val) => ({
        quantity: val.quantity,
        productId: val.id,
        specificationList: val.ingredients.map((spec) => ({
          ingredientId: spec.id,
          specification: ingredientTypes.find((type) => type.id === spec.type)?.name ?? 'სტანდარტი'
        }))
      })),
      PaymentMethod: data.payType
    });

    return res.data;
  }

  async addUserOrder(data) {
    const res = await axios.post('/api/Order/ClientAddOrder', {
      clientPhoneNumber: data.phone,
      address: {
        lat: data.address.lat || 0,
        lng: data.address.lng || 0,
        userAddress: data.address.address
      },
      branchIds: data.branches,
      recipientUserId: null,
      orderType: data.orderType,
      paymentMethod: data.paymentType,
      description: data.note,
      statusId: 2,
      productList: data.items.map((val) => ({
        quantity: val.quantity,
        productId: val.id,
        specificationList: val.ingredients.map((spec) => ({
          ingredientId: spec.id,
          specification: ingredientTypes.find((type) => type.id === spec.type)?.name ?? 'სტანდარტი'
        }))
      }))
    });

    return res.data;
  }

  async validateNumber(data) {
    const res = await axios.post(
      `/api/Order/OrderAuthConfirmationWithAuthKey?orderId=${data.orderId}&authKey=${data.code}`
    );

    return res.data;
  }

  async sendAuthCodeAgain(orderId) {
    const res = await axios.post(`/api/Order/SendNewAuthKey?orderId=${orderId}`);
    return res.data;
  }

  async deleteOrder(orderId) {
    const res = await axios.delete(`/api/Order/RemoveOrder?orderId=${orderId}`);
    return res;
  }

  async getByTrackerId(tracker) {
    const res = await axios.get(
      `/api/Order/GetOrderByTrackerId?trackerId=${encodeURIComponent(
        tracker
      )}`
    );
    return res;
  }
}
