import { LoadButton } from 'components/LoadButton/LoadButton';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { OrderService } from 'services';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Divider, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  summary: {
    margin: '0 15px'
  },
  productsGrid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '100%'
    }
  },
  productItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.9em'
  },
  ingredient: {
    padding: '0 !important',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column'
    }
  }
});

export const CurrentOrder = (props) => {
  const orderService = new OrderService();
  const [orders, setOrders] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(undefined);
  const id = useSelector((state) => state.user.id);

  const getOrders = async () => {
    const res = await orderService.getOrders({ statusCodes: [7], courierId: id });
    setOrders(res);
  };

  const classes = useStyles();

  useEffect(() => {
    id && getOrders();
  }, [id]);

  const endOrder = async (val) => {
    setLoadingIndex(val.orderId);
    await orderService.endOrder(val.orderId);
    await getOrders();
    await props.endWatcher(val.trackerId);
    setLoadingIndex(undefined);
  };

  return orders.map((val) => (
    <div>
      <div style={{ borderBottom: '2px solid black', marginTop: '2em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h3>მისმართი: {val.address.userAddress}</h3>
            <h3>
              ნომერი: <a href={`tel:+995${val.clientPhoneNumber}`}>{val.clientPhoneNumber}</a>
            </h3>
            <h3>შეკვეთის ნომერი: {val.code}</h3>
          </div>
          <div>
            <h3>პროდუქტის საფასური: {val.price}₾</h3>
            <h3>მიტანის საფასური: {val.deliveryPrice}₾</h3>
            <h3>ჯამი: {val.price + val.deliveryPrice}₾</h3>
          </div>
        </div>
        <Divider style={{ margin: '15px 0' }} />
        <div>
          {val.productList.map((val) => {
            return (
              <List>
                <ListItem className={classes.ingredient}>
                  <ListItemText>
                    პროდუქტი - {val.name} - {val.quantity}X
                  </ListItemText>
                  <List>
                    {val.specificationList.map((val) => {
                      return (
                        <ListItem className={classes.ingredient}>
                          <ListItemText
                            secondary={`${val.ingredient.name} - ${val.specification}`}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </ListItem>
              </List>
            );
          })}
        </div>
        <div style={{ margin: '2em 0' }}>
          <LoadButton onClick={() => endOrder(val)} loading={loadingIndex === val.orderId}>
            ჩაბარდა
          </LoadButton>
        </div>
      </div>
      <div
        style={{ marginTop: 50, display: val.address.lat || val.address.lng ? 'initial' : 'none' }}>
        <GoogleMap
          id='circle-example'
          mapContainerStyle={{
            height: '400px',
            width: '100%'
          }}
          center={{
            lat: val.address.lat,
            lng: val.address.lng
          }}
          zoom={19}
          mapTypeId={window.google.maps.MapTypeId.ROADMAP}
          animation={'bounce'}>
          <Marker
            position={{
              lat: val.address.lat,
              lng: val.address.lng
            }}
            zIndex={100000}
          />
        </GoogleMap>
      </div>
    </div>
  ));
};
