import { Button, InputAdornment, TextField, useTheme } from '@material-ui/core';
import { Lock, Person } from '@material-ui/icons';
import logo from 'assets/logo.jpg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOG_IN } from 'redux/actions/userActions';
import { AuthorizationService } from 'services';
import { toast } from 'react-toastify';
import './Login.css';

export const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: '',
    password: ''
  });

  const authorizationService = new AuthorizationService();

  const onChange = (evt) => {
    const { name, value } = evt.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitHandler = async (ev) => {
    ev.preventDefault();
    try {
      const res = await authorizationService.login(state.name, state.password);
      if (res.ok) {
        return dispatch({
          type: LOG_IN,
          payload: {
            username: state.name,
            role: res.data.value.userInfo.role,
            id: res.data.value.userInfo.id
          }
        });
      }
      toast.error(`დაფიქსირდა შეცდომა`, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } catch (e) {
      toast.error(`დაფიქსირდა შეცდომა`, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  return (
    <div style={{ backgroundColor: theme.palette.neutralLighter }} className='login-page-wrapper'>
      <form
        style={{ backgroundColor: theme.palette.white }}
        className='login-form-wrapper'
        onSubmit={submitHandler}>
        <img src={logo} alt='მაქს შაურმა' className='login-form-image' />

        <TextField
          label='მომხმარებელი'
          required
          onChange={onChange}
          name='name'
          value={state.name}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Person />
              </InputAdornment>
            )
          }}
        />

        <TextField
          label='პაროლი'
          required
          onChange={onChange}
          name='password'
          type='password'
          value={state.password}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Lock />
              </InputAdornment>
            )
          }}
        />

        <Button
          variant='contained'
          type='submit'
          style={{ background: theme.palette.themePrimary, color: theme.palette.white }}>
          შესვლა
        </Button>
      </form>
    </div>
  );
};
