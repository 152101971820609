import { GET_ROLES } from "./types";

const initialState = {
    roles: []
};

export const roles = (state = initialState, action) => {
    switch(action.type){
        case GET_ROLES:
            return {
                ...state,
                roles: action.payload
            };
        default: return state;
    };
};