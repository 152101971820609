import { IconButton, TableCell, TableRow } from '@material-ui/core';
import { Create, Delete } from '@material-ui/icons';
import { AddCatalogueProduct } from 'components';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProducts } from 'redux/products/actions';
import { ProductsService } from 'services';
import { arrayToText, shortifyText } from 'utils';

export const ProductRow = ({
  name,
  ingredients,
  categoryName,
  categoryId,
  id,
  price,
  imagePath,
  primeCost,
  storageProduct,
  storageProductQuantity
}) => {
  const productsService = new ProductsService();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const deleteHandler = async () => {
    setDeleteLoading(true);
    await productsService.deleteProduct(id);
    setDeleteLoading(false);
    setDeleted(true);
  };

  const updateHandler = async (data) => {
    await productsService.editProduct({ ...data, id });
    dispatch(setProducts());
  };

  if (deleted) {
    return null;
  }

  return (
    <>
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell>{shortifyText(categoryName, 30)}</TableCell>
        <TableCell title={arrayToText(ingredients, 'name')}>
          {shortifyText(arrayToText(ingredients, 'name'), 30)}
        </TableCell>
        <TableCell>{price}₾</TableCell>
        <TableCell>{primeCost}₾</TableCell>
        <TableCell>
          <IconButton onClick={() => setEdit(true)}>
            <Create />
          </IconButton>
          <IconButton onClick={deleteHandler} disabled={deleteLoading}>
            <Delete style={{ fill: 'red' }} />
          </IconButton>
        </TableCell>
      </TableRow>{' '}
      <AddCatalogueProduct
        preData={{
          ingredients,
          categoryNameId: categoryId,
          categoryName,
          name,
          price,
          id,
          imagePath,
          primeCost,
          storageProductQuanitity: Number(storageProductQuantity) || '0',
          storageProductId: storageProduct?.id ?? ''
        }}
        isOpen={edit}
        closeHandler={() => setEdit(false)}
        onProceed={updateHandler}
      />
    </>
  );
};
