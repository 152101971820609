import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Login, Admin, Courier, Homepage, ProductList, OrderTracker } from 'containers';
import { navigation } from 'constants/index';
import { AccessRoute } from './AccessRoute';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserDetails } from 'redux/user/actions';
import { getBranches } from 'redux/branches/actions';
import { getRoles } from 'redux/roles/actions';
import {
  getCategories,
  getMeasurments,
  getStatuses,
  getStorageItems
} from 'redux/products/actions';
import { Roles } from 'data';
import { AuthorizationService } from 'services';
import { TVSCreen } from 'containers/screens/tvScreen/TVScreen';
import { Earned } from 'containers/screens/Earned/Earned';

export const Routing = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(getUserDetails());
      dispatch(getRoles());
      dispatch(getMeasurments());
      dispatch(getStorageItems());
    }
    dispatch(getBranches());
    dispatch(getCategories());
    dispatch(getStatuses());
  }, [dispatch, user.isLoggedIn]);

  return (
    <Router>
      <Switch>
        <Redirect exact from='/' to={navigation.admin.login} />
        <AccessRoute
          path={navigation.admin.login}
          access={!user.isLoggedIn}
          redirect={
            user.role === Roles.courier ? navigation.courier.dashboard : navigation.admin.dashboard
          }>
          <Login />
        </AccessRoute>
        <AccessRoute
          onlyChildren
          path={navigation.admin.dashboard}
          access={
            user.isLoggedIn &&
            [
              Roles.admin,
              Roles.manager,
              Roles.cashier,
              Roles.administrator,
              Roles.operator
            ].includes(user.role)
          }
          redirect={navigation.admin.login}>
          <Admin />
        </AccessRoute>
        <AccessRoute
          onlyChildren
          path={navigation.courier.dashboard}
          access={user.isLoggedIn && user.role === Roles.courier}
          redirect={navigation.admin.login}>
          <Courier />
        </AccessRoute>
        {/* <Route exact path={navigation.main.homepage}>
          <Homepage />
        </Route>
        <Route exact path={navigation.main.products}>
          <ProductList />
        </Route>
        <Route exact path={navigation.main.tracker(':trackerId')}>
          <OrderTracker />
        </Route>
        <Route exact path={navigation.main.tv(':id')}>
          <TVSCreen />
        </Route>
        <Route exact path={navigation.admin.courierSalary(':id', ':name')}>
          <Earned />
        </Route> */}
      </Switch>
    </Router>
  );
};
