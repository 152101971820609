import { ManagerService, ProductsService, StorageService } from 'services';
import {
  GET_CATEGORIES,
  GET_MEASURMENTS,
  GET_STATUSES,
  GET_STORAGE_ITEMS,
  SET_PRODUCTS
} from './types';

export const setProducts = (index, branchId) => async (dispatch) => {
  const productsService = new ProductsService();
  const res = await productsService.getProducts(index, branchId);
  dispatch({
    type: SET_PRODUCTS,
    payload: res
  });
};

export const getMeasurments = () => async (dispatch) => {
  const managerService = new ManagerService();
  const res = await managerService.getMeasurementTypes();
  dispatch({
    type: GET_MEASURMENTS,
    payload: res
  });
};

export const getCategories = () => async (dispatch) => {
  const managerService = new ManagerService();
  const res = await managerService.getCategories();
  dispatch({
    type: GET_CATEGORIES,
    payload: res
  });
};

export const getStatuses = () => async (dispatch) => {
  const managerService = new ManagerService();
  const res = await managerService.getStatuses();
  dispatch({
    type: GET_STATUSES,
    payload: res
  });
};

export const getStorageItems = () => async (dispatch) => {
  const storageService = new StorageService();
  const res = await storageService.getProducts('null');
  dispatch({
    type: GET_STORAGE_ITEMS,
    payload: res
  });
};
