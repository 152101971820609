import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveries } from 'redux/deliveries/actions';
import {
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import { DashTernaryModal } from 'components';
import { makeStyles } from '@material-ui/styles';
import day from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { OrderService } from 'services';
import { OrderHistory } from 'containers';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon style={{ fontSize: '.9em' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    alignItems: 'center'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const useStyles = makeStyles({
  summary: {
    margin: '0 15px'
  },
  productsGrid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '100%'
    }
  },
  productItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.9em'
  },
  ingredient: {
    padding: '0 !important',
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column'
    }
  }
});

export const CourierOrderHistory = () => {
  const [expanded, setExpanded] = useState('');
  const [deliveredModal, setDeliveredModal] = useState({
    open: false,
    id: null
  });
  const [orders, setOrders] = useState([]);
  const [index, setIndex] = useState(0);
  const { id } = useSelector((state) => state.user);

  useEffect(() => {
    const orderService = new OrderService();
    const getOrders = async () => {
      const res = await orderService.getOrders({
        index,
        statusCodes: 8,
        orderType: 'DELIVERY',
        courierId: id
      });
      setOrders((prevState) => [...prevState, ...res]);
    };

    getOrders();
  }, [index]);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getDeliveries());
  }, [dispatch]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDelivery = () => {
    alert(deliveredModal.id);
  };

  const onStatusChange = (ev) => {
    ev.stopPropagation();
  };

  return (
    <div>
      <DashTernaryModal
        handleAgree={handleDelivery}
        handleClose={(e) => setDeliveredModal({ open: false, id: null })}
        open={deliveredModal.open}
        title='დარწმუნებული ხარ?'
        warning='დარწმუნებული ხარ რომ პროდუქტი ჩაბარდა?'
      />
      {orders.map((val) => {
        return (
          <Accordion expanded={expanded === val.id} onChange={handleChange(val.id)}>
            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
              <Typography>შეკვეთა: N{val.orderId}</Typography>
              <Typography
                style={{ marginLeft: 'auto', marginRight: 40 }}
                sx={{ color: 'text.secondary' }}>
                {val.address.userAddress}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.summary}>
                <div className={classes.productsGrid}>
                  <div>
                    <div className={classes.productItem}>
                      <p>ფილიალი</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p> {val.branch.addres}</p>
                    </div>
                    <div className={classes.productItem}>
                      <p>შეკვეთის დრო</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p>{day(val.recordTime).format('MM/DD/YYYY HH:mm')}</p>
                    </div>
                  </div>
                  <div>
                    <div className={classes.productItem}>
                      <p>მისამართი</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p>{val.address.userAddress}</p>
                    </div>
                    <div className={classes.productItem}>
                      <p>ნომერი</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p>{val.clientPhoneNumber}</p>
                    </div>
                  </div>
                </div>
                <Divider style={{ margin: '15px 0' }} />
                <div>
                  {val.productList.map((val) => {
                    return (
                      <List>
                        <ListItem className={classes.ingredient}>
                          <ListItemText>
                            პროდუქტი - {val.name} - {val.quantity}X
                          </ListItemText>
                          <List>
                            {val.specificationList.map((val) => {
                              return (
                                <ListItem className={classes.ingredient}>
                                  <ListItemText
                                    secondary={`${val.ingredient.name} - ${val.specification}`}
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </ListItem>
                      </List>
                    );
                  })}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
