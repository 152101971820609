import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Modal, Select, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { theme, Roles } from 'data';
import { useSelector } from 'react-redux';
import { LoadButton } from 'components/LoadButton/LoadButton';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    outline: 0,
    border: 'none'
  },
  block: {
    marginBottom: 25,
    '& > *': {
      width: '100%'
    }
  }
}));

export const SetProductModal = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const measurnmentTypes = useSelector((state) => state.products.measurments);
  const { role } = useSelector((state) => state.user);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'>
      <div className={classes.paper}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Close onClick={handleClose} style={{ cursor: 'pointer' }} />
        </div>
        <Typography variant='h5' style={{ textAlign: 'center', marginBottom: '40px' }}>
          {props.productModalFunction === 'add' ? 'დაამატე' : 'შეცვალე'} პროდუქტი
        </Typography>
        <div className={classes.block}>
          <TextField
            value={props.product.name}
            variant='outlined'
            label='დასახელება'
            onChange={props.handleChange}
            name='name'
          />
        </div>
        <div className={classes.block}>
          <FormControl variant='outlined'>
            <InputLabel htmlFor='measurement-type-selector'>საზომი ერთეული</InputLabel>
            <Select
              value={props.product.measurementId}
              onChange={(ev) => props.onMeasurementChange(ev.target.value, '')}
              native
              label='საზომი ერთეული'>
              {measurnmentTypes.map((val, idx) => {
                return (
                  <option value={val.id} key={idx}>
                    {val.measurment}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className={classes.block}>
          <TextField
            value={props.product.quantity}
            variant='outlined'
            label='რაოდენობა'
            onChange={props.handleChange}
            name={'quantity'}
            disabled={role !== Roles.admin && props.productModalFunction === 'edit'}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadButton
            disabled={loading}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await props.action();
              setLoading(false);
            }}
            variant='contained'
            style={{ background: theme.palette.themePrimary, color: theme.palette.white }}>
            {props.productModalFunction === 'add' ? 'დამატება' : 'შეცვლა'}
          </LoadButton>
        </div>
      </div>
    </Modal>
  );
};
