import { Chip, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
// import { LoadButton } from 'components/LoadButton/LoadButton';
import React from 'react';
// import { useState } from 'react';
import { useSelector } from 'react-redux';
import './SelectStorageProducts.css';

export const SelectStorageProduct = ({
  onAdd,
  storageItems,
  selectedProduct,
  setSelectedProduct,
  quantity,
  setQuantity
}) => {
  const items = useSelector((state) => state.products.storageProductItems);
  // const onProceed = (selectedProduct, quantity) => {
  //   const isAlreadyThere = storageItems.some((val) => val.id === selectedProduct);
  //   if (isAlreadyThere) {
  //     return;
  //   }
  //   onAdd({
  //     id: selectedProduct,
  //     quantity
  //   });
  //   setSelectedProduct('');
  //   setQuantity('0');
  // };

  return (
    <div>
      <h3>გასახარჯი პროდუქტები</h3>
      <div style={{ display: 'flex', width: '100%', padding: '1em 0' }}>
        <FormControl>
          <InputLabel id='demo-simple-select-label'>გასახარჯი პროდუქტი</InputLabel>
          <Select
            style={{ width: 150 }}
            value={selectedProduct}
            onChange={(ev) => {
              setSelectedProduct(ev.target.value);
            }}>
            {items.map((val, idx) => {
              return (
                <MenuItem key={idx} value={val.id}>
                  {val.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          label='რაოდენობა'
          value={quantity}
          onChange={(ev) => setQuantity(ev.target.value)}
          type='number'
        />
        {/*<LoadButton onClick={() => onProceed(selectedProduct, quantity)}>დამატება</LoadButton>*/}
      </div>
      {/*      <div>
        {storageItems.map((val, idx) => {
          const item = items.find((_item) => _item.id === val.id);
          return (
            <Chip
              key={`${val.id}_${idx}`}
              label={`${item.name} - ${val.quantity}`}
              onDelete={() => onDelete(val.id)}
            />
          );
        })}
      </div>*/}
    </div>
  );
};
