import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

export const LoadButton = ({ loading, children, ...props }) => {
  return (
    <Button disabled={loading} style={{ backgroundColor: 'red', color: '#fff' }} {...props}>
      {loading ? <CircularProgress size={20} style={{ marginRight: '0.5em' }} /> : null}
      {children}
    </Button>
  );
};
