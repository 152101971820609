import axios from 'axios';

export class CategoriesService {
  async addCategory(name) {
    const res = await axios.post(`/api/Manager/AddCategory?category=${name}`);
    return res;
  }

  async editCategory(id, name) {
    const res = await axios.put(`/Admin/UpdateCategory?id=${id}&categoryName=${name}`);
    return res;
  }

  async deleteCategory(id) {
    const res = await axios.delete(`/api/Manager/RemoveCategory?id=${id}`);
    return res;
  }
}
