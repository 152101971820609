import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  useTheme
} from '@material-ui/core';
import { FileUpload } from 'components';
import { LoadButton } from 'components/LoadButton/LoadButton';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import './AddCatalogueProduct.css';
import { SelectStorageProduct } from './SelectStorageProducts/SelectStorageProducts';

export const AddCatalogueProduct = ({ preData, closeHandler, onProceed, isOpen }) => {
  const [state, setState] = useState(
    preData ?? {
      name: '',
      measurementType: '',
      ingredients: [],
      categoryName: '',
      categoryNameId: '',
      price: '',
      imagePath: '',
      primeCost: '',
      storageProductId: '',
      storageProductQuanitity: '',
      branchId: '',
      isActive: true
    }
  );

  const branches = useSelector((state) => state.branches.branches);

  const onQuantityChange = (x) => {
    setState((prevState) => ({
      ...prevState,
      storageProductQuanitity: x
    }));
  };

  const onStorageProductIdChange = (x) => {
    setState((prevState) => ({
      ...prevState,
      storageProductId: x
    }));
  };

  const addStorageItems = (storageItem) => {
    setState((prevState) => ({
      ...prevState,
      storageItems: [...prevState.storageItems, storageItem]
    }));
  };

  const onStorageItemDelete = (id) => {
    setState((prevState) => ({
      ...prevState,
      storageItems: prevState.storageItems.filter((val) => val.id !== id)
    }));
  };

  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const [variable, setVariable] = useState('');
  const { categories } = useSelector((state) => state.products);

  useEffect(() => {
    if (categories.length && !preData) {
      setState((prevState) => ({
        ...prevState,
        categoryName: categories[0].name,
        categoryNameId: categories[0].id
      }));
    }
  }, [categories]);

  const onChange = (evt) => {
    const { name, value } = evt.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const addVariable = () => {
    setState((prevState) => ({
      ...prevState,
      ingredients: [...prevState.ingredients, variable]
    }));
    setVariable('');
  };

  const deleteVariable = (id) => {
    setState((prevState) => ({
      ...prevState,
      ingredients: prevState.ingredients.filter((val, i) => val !== id)
    }));
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeHandler}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'პროდუქტის დამატება'}</DialogTitle>
        <DialogContent>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '1em' }}>
            <TextField
              name={'name'}
              onChange={onChange}
              variant={'outlined'}
              label={'სახელი'}
              value={state.name}
            />
            <FormControl variant='outlined'>
              <InputLabel htmlFor='outlined-age-native-simple'>კატეგორია</InputLabel>
              <Select
                native
                label='კაგეგორია'
                onChange={onChange}
                name={'categoryNameId'}
                value={state.categoryNameId}>
                {categories.map((val, idx) => {
                  return (
                    <option key={idx} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              name={'price'}
              onChange={onChange}
              variant={'outlined'}
              label={'ფასი'}
              value={state.price}
              type='number'
              InputProps={{
                endAdornment: '₾'
              }}
            />
            <TextField
              name={'primeCost'}
              onChange={onChange}
              variant={'outlined'}
              label={'თვითღირებულება'}
              value={state.primeCost}
              type='number'
              InputProps={{
                endAdornment: '₾'
              }}
            />
            <FormControl>
              <InputLabel>ფილიალი</InputLabel>
              <Select
                label={'ფილიალი'}
                variant={'outlined'}
                value={state.branchId}
                onChange={(ev) => setState({ ...state, branchId: ev.target.value })}
                name='role'>
                {branches.map((branch, idx) => {
                  return (
                    <MenuItem key={`${branch.id}_${idx}`} value={branch.id}>
                      {branch.addres}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FileUpload
              value={file ?? state.imagePath}
              onChange={(ev) => setFile(ev?.target?.files[0])}
              label='ფოტოს ატვირთვა'
            />
            <div className='long-column'>
              <h3>ცვლადი პროდუქტები</h3>
              <div className='part-form-flex'>
                <TextField
                  label='სახელი'
                  variant={'outlined'}
                  style={{ width: '50%' }}
                  value={variable}
                  onChange={(ev) => setVariable(ev.target.value)}
                />
                <Button
                  style={{ background: theme.palette.themePrimary, color: theme.palette.white }}
                  onClick={addVariable}>
                  დამატება
                </Button>
              </div>
              <div style={{ marginTop: 10 }}>
                {state.ingredients.map((val, idx) => (
                  <Chip
                    key={`${val}_${idx}`}
                    label={typeof val === 'string' ? val : val.name}
                    onDelete={() => deleteVariable(val)}
                  />
                ))}
              </div>
              <SelectStorageProduct
                onAdd={addStorageItems}
                storageItems={state.storageItems}
                onDelete={onStorageItemDelete}
                selectedProduct={state.storageProductId}
                setSelectedProduct={onStorageProductIdChange}
                quantity={state.storageProductQuanitity}
                setQuantity={onQuantityChange}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.isActive}
                    onChange={(ev, checked) => {
                      setState((prevState) => ({
                        ...prevState,
                        isActive: checked
                      }));
                    }}
                  />
                }
                label='პროდუქტი აქტიურია'
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHandler} color='primary'>
            გაუქმება
          </Button>
          <LoadButton
            style={{ color: 'red' }}
            disabled={loading}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await onProceed({
                categoryId: state.categoryNameId,
                price: state.price,
                name: state.name,
                ingredients: state.ingredients,
                primeCost: state.primeCost,
                Image: file,
                storageProductId: state.storageProductId,
                storageProductQuanitity: state.storageProductQuanitity
              });
              setLoading(false);
              closeHandler();
            }}>
            {preData ? 'შეცვლა' : 'დამატება'}
          </LoadButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
