import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const Earned = () => {
  const [dates, setDates] = useState({
    0: '',
    1: ''
  });

  const params = useParams();

  const [earned, setEearned] = useState('');

  const { id } = useSelector((state) => state.user);

  const onDateChange = (ev) => {
    ev.persist();
    setDates((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }));
  };

  useEffect(() => {
    const getEarned = async (dates) => {
      const res = await axios.get(
        `/api/Order/GetCourierSalary?firstDateTime=${dates[0]}&lastDateTime=${dates[1]}&courierId=${
          params.id || id
        }&finishedOrderStatusCodeId=8`
      );
      setEearned(res.data.salary);
    };

    if (dates[0] && dates[1]) {
      getEarned(dates);
    }
  }, [dates]);

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ margin: '2em 0' }}>
        {params.name ? <h3>{params.name}</h3> : null}
        <input type='date' value={dates[0]} onChange={onDateChange} name='0' />
        <input type='date' value={dates[1]} onChange={onDateChange} name='1' />
      </div>
      {dates[0] && dates[1] ? (
        <h2>
          {params.id ? 'კურიერმა გამოიმუშავა' : 'თქვენ გამოიმუშავეთ'}: {earned}₾
        </h2>
      ) : (
        <h2>მონიშნეთ თარიღები</h2>
      )}
    </div>
  );
};
