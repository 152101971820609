import { GET_USER } from './types';

const initialState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  branchId: undefined,
  role: ''
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        firstName: action.payload.firstname,
        lastName: action.payload.lastname,
        phoneNumber: action.payload.phoneNumber,
        branchId: action.payload.branchId,
        role: action.payload.role
      };
    default:
      return state;
  }
};
