import { makeStyles } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import {
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import {ingredientTypes} from 'data';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: '2px !important',
    paddingRight: '2px !important'
  },
  ingredient: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  action: {
    backgroundColor: theme.palette.neutralLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 7,
    cursor: 'pointer',
    borderRadius: 100,
    '& > svg': {
      width: 22,
      height: 22,
    }
  }
}));

export const Ingredient = (props) => {
  const classes = useStyles();
  let ingredientType = ingredientTypes.find(e => e.id === props.type);
  return (
    <ListItem className={classes.listItem}>
      <div className={classes.ingredient}>
        <div onClick={e => props.handleIngredientTypeChange(props.id, "decrease")} className={classes.action}>
          <Remove />
        </div>
        <Typography
          variant='body2'
          style={{ borderBottom: '2px solid black', textAlign: 'center', margin: '0 20px', paddingBottom: '5px', width: '100%', userSelect: 'none' }}>
          {props.name} - {ingredientType.name}
        </Typography>
        <div onClick={e => props.handleIngredientTypeChange(props.id, "increase")} className={classes.action}>
          <Add />
        </div>
      </div>
    </ListItem>
  );
};
