import * as Styled from './Product.styles';
import { FaCartPlus } from 'react-icons/fa';

export const Product = ({ name, price, imagePath, categoryName, onClick }) => {
  return (
    <Styled.Product onClick={onClick}>
      <Styled.Img>
        <img src={imagePath} />
      </Styled.Img>
      <Styled.Description>
        <Styled.DecsRow>
          <Styled.Name>{name}</Styled.Name>
          <Styled.Price>{price}₾</Styled.Price>
        </Styled.DecsRow>
        <Styled.DecsRow>
          <Styled.Cat>{categoryName}</Styled.Cat>
          <Styled.Icons>
            <FaCartPlus />
          </Styled.Icons>
        </Styled.DecsRow>
      </Styled.Description>
    </Styled.Product>
  );
};
