import { Button } from '@material-ui/core';
import { CloudUpload as FileUploadIcon } from '@material-ui/icons';
import React, { useRef } from 'react';
import './FileUpload.css';

export const FileUpload = ({ value, onChange, enablePreview = true, label }) => {
  const inputRef = useRef();

  const displayFile = (val) => {
    if (typeof val === 'string') {
      return val;
    }

    return URL.createObjectURL(val);
  };

  const onPictureClick = (ev) => {
    if (enablePreview) {
      window.open(ev.target.src, '_blank');
    }
  };

  return (
    <div className='file-upload-wrapper'>
      <span>{label}</span>
      <div className='file-upload-container'>
        <input
          type='file'
          value={value?.path ?? ''}
          onChange={onChange}
          style={{ display: 'none' }}
          ref={inputRef}
        />
        {value ? (
          <img
            src={displayFile(value)}
            alt='upload file'
            onClick={onPictureClick}
            style={{ cursor: enablePreview ? 'pointer' : 'unset' }}
          />
        ) : null}
        <Button color='primary' variant='contained' onClick={() => inputRef?.current?.click()}>
          <span style={{ marginRight: '1em' }}>{value ? 'ახლის ატვირთვა' : 'ფოტოს ატვირთვა'}</span>{' '}
          <FileUploadIcon />
        </Button>
      </div>
    </div>
  );
};
