import styled from 'styled-components';

export const Products = styled.div`
  padding-top: 55px;
`;

export const ProductsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 30px;
  gap: 50px;
  & > * {
    transition: 0.3s;
    &:hover {
      transform: translateY(-3px);
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Categories = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 25px;
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f8f8f7;
  }

  &::-webkit-scrollbar-thumb {
    background: #00a852;
  }
`;

export const Category = styled.div`
  color: ${(props) => (props.active ? '#00a852' : '#AAAAAA')};
  margin: 0 5px;
  font-size: 1em;
  cursor: pointer;
  font-weight: 500;
  transition-duration: 0.2s;
  border: 2px solid ${(props) => (props.active ? '#00a852' : '#AAAAAA')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 25px;
  white-space: nowrap;
  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;