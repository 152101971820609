import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  useTheme
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { UsersService } from 'services';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { LoadButton } from 'components/LoadButton/LoadButton';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: '0 15px'
  },
  listItem: {
    margin: '5px 0 !important'
  },
  blockBtn: {
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white'
    }
  }
}));

export const Users = () => {
  const [users, setUsers] = useState([]);
  const usersService = useMemo(() => new UsersService(), []);

  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [index, setIndex] = useState(1);
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      setSearchLoading(true);
      await getUsers(search, index);
      setSearchLoading(false);
    })();
  }, [search, index]);

  const getUsers = useCallback(
    async (search, index) => {
      const res = await usersService.getUsers(search, index);
      setUsers((prevState) => [...prevState, ...res.data]);
    },
    [usersService]
  );

  const classes = useStyles();

  const blockUser = async (number) => {
    const res = await usersService.blockUser(number);
    if (res.ok) {
      getUsers();
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '2em', textAlign: 'center' }}>
        <TextField
          variant='outlined'
          value={searchInput}
          label='ძიება'
          onChange={(ev) => setSearchInput(ev.target.value)}
        />
        <LoadButton
          variant='contained'
          color='primary'
          onClick={() => {
            setUsers([]);
            setSearch(searchInput);
            setIndex(1);
          }}
          loading={searchLoading}>
          ძებნა
        </LoadButton>
      </div>
      <List dense className={classes.root}>
        {users.map((value, idx) => {
          const labelId = `checkbox-list-secondary-label-${value}`;

          return (
            <React.Fragment>
              <ListItem className={classes.listItem} key={value} button>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${value + 1}`}
                    src={`/static/images/avatar/${value + 1}.jpg`}
                  />
                </ListItemAvatar>
                <a href={`tel:+995${value.phoneNumber}`}>{value.phoneNumber}</a>
                <ListItemSecondaryAction>
                  <Button
                    endIcon={<BlockIcon />}
                    variant='contained'
                    className={classes.blockBtn}
                    size='small'
                    color='inherit'
                    onClick={() => blockUser(value.phoneNumber)}
                    disableElevation>
                    დაბლოკვა
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              {idx !== users.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
      <div style={{ textAlign: 'center', margin: '1em 0' }}>
        <Button
          onClick={() => setIndex((prevState) => prevState + 1)}
          variant='contained'
          style={{ background: theme.palette.themePrimary, color: theme.palette.white }}>
          მეტის ჩატვირთვა
        </Button>
      </div>
    </div>
  );
};
