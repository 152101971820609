import { TableCell, TableRow, TableBody } from '@material-ui/core';
import React from 'react';

export const ReportTable = ({ report }) => {
  return (
    <React.Fragment>
      <TableBody>
        <TableRow>
          <TableCell>{report.income}₾</TableCell>
          <TableCell>{report.incomeWithCash}₾</TableCell>
          <TableCell>{report.incomeWithTerminal}₾</TableCell>
          <TableCell>{report.expense}₾</TableCell>
          <TableCell>{report.profit}₾</TableCell>
        </TableRow>
      </TableBody>
    </React.Fragment>
  );
};
