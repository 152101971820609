import { Divider, ListItem, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { FormControl, InputLabel, Paper, Select, List, TextField } from '@mui/material';
import { Ingredient } from 'components/Ingredient/Ingredient';
import { useSelector } from 'react-redux';
import { ingredientTypes } from 'data';
import { Add, Remove } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px 15px',
    height: '100%'
  },
  header: {
    marginBottom: 25,
    textAlign: 'center'
  },
  selectProduct: {
    width: '100%'
  },
  ingredients: {
    marginTop: '12px !important'
  },
  amount: {
    padding: '10px 12px'
  },
  action: {
    backgroundColor: theme.palette.neutralLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 7,
    cursor: 'pointer',
    borderRadius: 100,
    '& > svg': {
      width: 24,
      height: 24
    }
  }
}));

export const OrderForm = (props) => {
  const classes = useStyles();
  const products = useSelector((state) => state.products.products);
  const productExists = !!props.val.data.product.id;

  const handleIngredientTypeChange = (id, action) => {
    let newIngredients = props.val.data.product.ingredients.map((val) => {
      if (id === val.id) {
        let currentType = ingredientTypes.find((e) => e.id === val.type);
        return {
          ...val,
          type:
            action === 'increase'
              ? currentType.next
                ? currentType.next
                : val.type
              : currentType.previous
              ? currentType.previous
              : val.type
        };
      }
      return val;
    });
    props.handleProductChange(props.val.templateId, 'ingredients', newIngredients);
  };

  return (
    <Paper className={classes.paper} elevation={1}>
      <div>
        <FormControl className={classes.selectProduct}>
          <InputLabel htmlFor='product-name-selector'>პროდუქტი</InputLabel>
          <Select
            onChange={(e) => props.handleProductIdChange(props.val.templateId, e.target.value)}
            value={props.val.data.product.id}
            labelId='product-name-selector'
            label='პროდუქტი'>
            {products.map((val) => {
              return <MenuItem value={val.id}>{val.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      <div style={{ display: productExists ? 'block' : 'none' }}>
        <List className={classes.ingredients}>
          {props.val.data.product.ingredients.map((val) => {
            return (
              <div style={{ marginBottom: '5px' }}>
                <Ingredient {...val} handleIngredientTypeChange={handleIngredientTypeChange} />
              </div>
            );
          })}
        </List>
        <div style={{ margin: '30px 0', display: 'flex', alignItems: 'flex-end' }}>
          <div
            onClick={(e) => {
              if (props.val.data.quantity === 1) {
                return;
              }
              props.handleTemplateItemChange(
                props.val.templateId,
                'quantity',
                parseInt(props.val.data.quantity) - 1
              );
            }}
            className={classes.action}>
            <Remove />
          </div>
          <TextField
            onChange={(e) =>
              props.handleTemplateItemChange(props.val.templateId, 'quantity', e.target.value)
            }
            value={props.val.data.quantity}
            style={{ width: '100%', margin: '0 5%' }}
            variant='standard'
            label='რაოდენობა'
            type='number'
            inputProps={{
              min: 1
            }}
          />
          <div
            onClick={(e) =>
              props.handleTemplateItemChange(
                props.val.templateId,
                'quantity',
                parseInt(props.val.data.quantity) + 1
              )
            }
            className={classes.action}>
            <Add />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography style={{ marginRight: '15px' }}>ფასი:</Typography>
          <Typography variant='h5'>
            {props.val.data.quantity * props.val.data.product.price}ლ
          </Typography>
        </div>
      </div>
    </Paper>
  );
};
