import React, { useState } from 'react';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  IconButton
} from '@material-ui/core';
import { LOG_OUT } from 'redux/actions/userActions';
import logo from 'assets/logo.jpg';
import { makeStyles } from '@material-ui/core/styles';
import {
  Fastfood,
  GroupAdd,
  Kitchen,
  Group,
  ExitToApp,
  Computer,
  FastfoodOutlined,
  BlockRounded,
  PersonPinCircleRounded,
  History,
  LocalPharmacyOutlined,
  HomeWorkOutlined,
  AssessmentOutlined,
  AccountBalanceWalletOutlined,
  ListAltOutlined,
  TwoWheeler,
  Motorcycle,
  HourglassEmpty
} from '@material-ui/icons';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { navigation } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddLocationAltOutlined } from '@mui/icons-material';
import { Roles, theme } from 'data';
import MenuIcon from '@mui/icons-material/Menu';

export const sidebarWidth = 280;

const useStyles = makeStyles((props) => ({
  drawer: {
    width: sidebarWidth
  },
  drawerPaper: {
    minWidth: sidebarWidth,
    height: '100vh',
    '@media (max-width: 768px)': {
      height: '100%'
    }
  },
  logoContainer: {
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center'
  },
  logoImgContainer: {
    width: '45px',
    marginRight: '15px',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  },
  topList: {
    flex: 1,
    padding: '20px 0'
  },
  listItem: {
    color: theme.palette.neutralPrimary,
    fill: theme.palette.neutralPrimary
  },
  activeListItem: {
    color: theme.palette.themePrimary,
    fill: theme.palette.themePrimary
  },
  listItemIcon: {
    color: 'inherit',
    minWidth: '43px',
    '& svg': {
      fill: 'inherit'
    }
  }
}));

const topOptions = [
  {
    text: 'პროფილი',
    icon: <PersonPinCircleRounded />,
    link: navigation.admin.profile,
    access: () => true
  },
  {
    text: 'შეკვეთა',
    icon: <Fastfood />,
    link: navigation.admin.orders,
    access: () => true
  },
  {
    text: 'მიმდინარე შეკვეთები',
    icon: <ListAltOutlined />,
    link: navigation.admin.ongoings,
    access: () => true
  },
  {
    text: 'დაუდასტურებელი შეკვეთები',
    icon: <HourglassEmpty />,
    link: navigation.admin.pendings,
    access: (role) => [Roles.operator, Roles.admin].includes(role)
  },
  {
    text: 'შეკვეთების ისტორია',
    icon: <History />,
    link: navigation.admin.orderHistory,
    access: () => true
  },
  {
    text: 'შეკვეთები გზაში',
    icon: <TwoWheeler />,
    link: navigation.admin.onRoad,
    access: (role) => [Roles.admin, Roles.cashier, Roles.operator, Roles.manager].includes(role)
  },
  {
    text: 'მომხმარებლები',
    icon: <Group />,
    link: navigation.admin.users,
    access: (role) => [Roles.admin, Roles.manager].includes(role)
  },
  {
    text: 'კურიერები',
    icon: <Motorcycle />,
    link: navigation.admin.couriers,
    access: (role) => [Roles.admin].includes(role)
  },
  {
    text: 'დაბლოკილები',
    icon: <BlockRounded />,
    link: navigation.admin.blockedUsers,
    access: (role) => [Roles.admin, Roles.manager].includes(role)
  },
  {
    text: 'საწყობი',
    icon: <Kitchen />,
    link: navigation.admin.storage,
    access: (role) => [Roles.admin, Roles.manager, Roles.administrator].includes(role)
  },
  {
    text: 'თანამშრომლები',
    icon: <GroupAdd />,
    link: navigation.admin.employees,
    access: (role) => [Roles.admin, Roles.manager, Roles.administrator].includes(role)
  },
  {
    text: 'აღრიცხვა',
    icon: <Computer />,
    link: navigation.admin.reports,
    access: (role) => [Roles.admin, Roles.manager].includes(role)
  },
  {
    text: 'პროდუქტები',
    icon: <FastfoodOutlined />,
    link: navigation.admin.products,
    access: (role) => [Roles.admin].includes(role)
  },
  {
    text: 'კატეგორიები',
    icon: <LocalPharmacyOutlined />,
    link: navigation.admin.categories,
    access: (role) => [Roles.admin].includes(role)
  },
  {
    text: 'ფილიალები',
    icon: <HomeWorkOutlined />,
    link: navigation.admin.branches,
    access: (role) => [Roles.admin].includes(role)
  },
  {
    text: 'საზომი ერთეულები',
    icon: <AssessmentOutlined />,
    link: navigation.admin.measurements,
    access: (role) => [Roles.admin].includes(role)
  }
];

const courierOptions = [
  {
    text: 'შეკვეთები',
    icon: <FastfoodOutlined />,
    link: navigation.courier.orders,
    access: () => true
  },
  {
    text: 'შეკვეთების ისტორია',
    icon: <History />,
    link: navigation.courier.orderHistory,
    access: () => true
  },
  {
    text: 'მიმდინარე შეკვეთები',
    icon: <AddLocationAltOutlined />,
    link: navigation.courier.currentOrder,
    access: () => true
  },
  {
    text: 'გამომუშავებული',
    icon: <AccountBalanceWalletOutlined />,
    link: navigation.courier.earned,
    access: () => true
  }
];

const optionsHelper = {
  admin: topOptions,
  courier: courierOptions
};

export const Sidebar = (props) => {
  const classes = useStyles(props);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [activeOption, setActiveOption] = useState('შეკვეთები');
  const options = optionsHelper[props.sidebarType];
  const [isOpen, setIsOpen] = useState(false);

  const matches = useMediaQuery('(min-width:768px)');

  useEffect(() => {
    let activeOption = options.find((e) => e.link === location.pathname);

    if (activeOption) {
      setActiveOption(activeOption.link);
    }
  }, [location]);

  const handleOptionClick = (val) => {
    history.push(val.link);
  };

  return (
    <>
      {!matches ? (
        <IconButton
          style={{ position: 'fixed', top: '0.5em', left: '0.5em' }}
          onClick={() => setIsOpen(true)}>
          <MenuIcon />
        </IconButton>
      ) : null}
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        anchor='left'
        variant={!matches ? undefined : 'permanent'}>
        <div className={classes.logoContainer}>
          <div className={classes.logoImgContainer}>
            <img src={logo} alt='max shaurma' />
          </div>
          <Typography variant='body1'>{user.username}</Typography>
        </div>
        <Divider />
        <List className={classes.topList}>
          {options.map((val, i) => {
            if (!val?.access(user.role)) {
              return null;
            }

            return (
              <ListItem
                className={`${classes.listItem} ${
                  val.link === activeOption && classes.activeListItem
                }`}
                button
                key={val.text}
                onClick={(e) => handleOptionClick(val)}>
                <ListItemIcon className={classes.listItemIcon}>{val.icon}</ListItemIcon>
                <ListItemText primary={val.text} />
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => dispatch({ type: LOG_OUT })}>
            <ListItemIcon className={classes.listItemIcon}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary={`გასვლა`} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
