import axios from 'axios';
import qs from 'query-string';

export class ReportsService {
  async getFullReports(params) {
    const res = await axios.get(`/api/Order/DetailedAccountingOrders?${qs.stringify(params)}`);
    return res.data;
  }

  async getReports(params) {
    const res = await axios.get(`/api/Order/GeneralAccountingOrders?${qs.stringify(params)}`);
    return res.data;
  }

  async getCashierReport(params) {
    const res = await axios.get(`/api/Order/GetCashierInfo?${qs.stringify(params)}`);
    return res.data;
  }
}
