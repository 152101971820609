import {
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme
} from '@material-ui/core';
import { OrderHistoryItem } from 'components';
import { LoadButton } from 'components/LoadButton/LoadButton';
import { Roles } from 'data';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { OrderService } from 'services/OrderService';

export const OrderHistory = () => {
  const theme = useTheme();
  const [orders, setOrders] = useState([]);
  const [index, setIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');

  const { branchId } = useSelector((state) => state.user);
  const { role } = useSelector((state) => state.user);

  const [onlyCanceled, setOnlyCanceled] = useState(false);

  useEffect(() => {
    setIndex(1);
    setOrders([]);
  }, [onlyCanceled]);

  useEffect(() => {
    const orderService = new OrderService();
    const getOrders = async () => {
      setLoading(true);
      const res = await orderService.getOrders({
        statusCodes: onlyCanceled ? [6] : [8, 6],
        branchId: branchId || undefined,
        searchingText: search,
        PageNumber: index
      });
      setOrders((prevState) => [...prevState, ...res]);
      setLoading(false);
    };

    index && getOrders();
  }, [index, branchId, search, onlyCanceled]);

  return (
    <div>
      <div style={{ marginBottom: '2em', textAlign: 'center' }}>
        <TextField
          variant='outlined'
          value={searchInput}
          label='ძიება'
          onChange={(ev) => setSearchInput(ev.target.value)}
        />
        <LoadButton
          variant='contained'
          color='primary'
          onClick={() => {
            if (searchInput === search) {
              return;
            }
            setOrders([]);
            setSearch(searchInput);
            setIndex(1);
          }}
          loading={loading}>
          ძებნა
        </LoadButton>
      </div>
      <FormControlLabel
        control={<Checkbox value={onlyCanceled} onChange={(ev, value) => setOnlyCanceled(value)} />}
        label='მხოლოდ გაუქმებულები'
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>ქვითარი</TableCell>
              <TableCell>სახელი</TableCell>
              <TableCell>ტელეფონი</TableCell>
              <TableCell>მისამართი</TableCell>
              <TableCell>ფილიალი</TableCell>
              <TableCell>თარიღი</TableCell>
              <TableCell>კურიერი</TableCell>
              <TableCell>ჯამი</TableCell>
              <TableCell>გადახდის მეთოდი</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row, i) => (
              <OrderHistoryItem key={`${row.price}_${i}`} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: 'center', margin: '1em 0' }}>
        <LoadButton
          onClick={() => setIndex((prevState) => prevState + 1)}
          loading={loading}
          variant='contained'
          style={{ background: theme.palette.themePrimary, color: theme.palette.white }}>
          მეტის ჩატვირთვა
        </LoadButton>
      </div>
    </div>
  );
};
