const {
  SET_PRODUCTS,
  GET_MEASURMENTS,
  GET_CATEGORIES,
  GET_STATUSES,
  GET_STORAGE_ITEMS
} = require('./types');

const initialState = {
  products: [],
  categories: [],
  measurments: [],
  statuses: [],
  storageProductItems: []
};

export const products = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };

    case GET_MEASURMENTS:
      return {
        ...state,
        measurments: action.payload
      };

    case GET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      };
    }

    case GET_STATUSES: {
      return {
        ...state,
        statuses: action.payload
      };
    }

    case GET_STORAGE_ITEMS:
      return {
        ...state,
        storageProductItems: action.payload
      };

    default:
      return state;
  }
};
