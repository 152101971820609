import axios from 'axios';

export class AuthorizationService {
  async login(name, password) {
    const res = await axios.post('/Admin/Login', {
      username: name,
      password
    });

    if (res.ok) {
      localStorage.setItem('token', `Bearer ${res.data.value.token}`);
    }

    return res;
  }

  async getInfo() {
    const res = await axios.get('/api/Account/GetAuthorizeUser');
    return res.data;
  }

  async logout() {
    const res = await axios.get('/admin/logout');
    localStorage.removeItem('token');

    return res;
  }
}
