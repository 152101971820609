import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
  margin: auto;
`;

export const Dates = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid black;
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const Date = styled.p`
  font-size: 16px;
`;

export const Code = styled.p`
  text-align: center;
  width: 100%;
  font-size: 25px;
  margin-top: 10px;
`;

export const ReceiptContainer = styled.div`
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;

export const TopPart = styled.div`
  width: 100%;
  flex: 1;
`;

export const OrderNumber = styled.div`
  font-size: 3em;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
`;

export const Welcome = styled.div`
  width: 100%;
  font-size: 1.1em;
  text-align: center;
`;

export const OrderDetails = styled.div``;

export const OrderDetail = styled.div``;

export const ProductWrapper = styled.div`
  margin: 22px 0;
`;

export const Ingredinets = styled.div`
  font-weight: 500;
  margin-top: 9px;
  padding: 0 10px;
`;

export const OrderDetailItem = styled.div`
  background-color: black;
  color: white;
  padding: 10px 10px;
  font-weight: bold;
`;

export const OrderDetailsTitle = styled.p`
  font-size: 1.4em;
`;

export const OrderDetailsWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ThankYou = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1em;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

export const InfoItem = styled.div``;

export const InfoItemDetail = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoItemDetailIcon = styled.div`
  margin-right: 8px;
  transform: scale(1.1);
`;

export const InfoItemDetailText = styled.div`
  font-size: 0.9em;
`;
