import axios from 'axios';
import { Routing } from './containers';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LOG_OUT } from 'redux/actions/userActions';
import store from 'redux/store';
import './index.css';

axios.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    config.headers.Authorization = localStorage.getItem('token') || '';
  }
  return {
    ...config,
    baseURL: 'https://maxdelivery.grena.ge'
  };
});

axios.interceptors.response.use(
  (response) => {
    return {
      ...response,
      ok: response.status === 200
    };
  },
  (error) => {
    console.log(JSON.stringify(error?.response));
    if (error?.response?.status === 401) {
      store.dispatch({ type: LOG_OUT });
    } else {
      toast.error(error?.response?.data?.message ?? error.message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }

    throw error;
  }
);

/* document.addEventListener('touchstart', function(event){
  event.preventDefault();
}, {passive: false});
 */
function App() {
  return (
    <div className='App'>
      <Routing />
      <ToastContainer
        position='bottom-left'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
