import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ProductRow, AddCatalogueProduct } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { setProducts } from 'redux/products/actions';
import { ProductsService } from 'services';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { TOGGLE_BRANCH } from 'redux/actions/userActions';

const useStyles = makeStyles({
  searchbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em 0'
  }
});

export const Products = () => {
  const [addCatalogueProduct, setAddCatalogueProduct] = useState(false);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const state = useSelector((state) => state.products.products);
  const userDetails = useSelector((state) => state.userDetails);
  const { role, branchId, id } = useSelector((state) => state.user);
  const branches = useSelector((state) => state.branches.branches);
  const classes = useStyles();
  const theme = useTheme();
  useEffect(() => {
    dispatch(setProducts(0, branchId));
  }, [branchId]);

  const productsService = new ProductsService();

  const addProduct = async (data) => {
    await productsService.addProduct(data);
    dispatch(setProducts(0));
  };

  const toggleBranch = (ev, newVal) => {
    dispatch({ type: TOGGLE_BRANCH, payload: newVal });
  };

  return (
    <div>
      <ToggleButtonGroup
        color='primary'
        exclusive
        style={{ marginBottom: '2em' }}
        value={branchId ?? userDetails.branchId}
        onChange={toggleBranch}>
        {branches.map((val) => {
          return (
            <ToggleButton key={val.id} value={val.id}>
              {val.addres} {val.city}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <div className={classes.searchbar}>
        <TextField
          startAdornment={<Search />}
          variant='outlined'
          label='ძიება'
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
        <Button
          variant='contained'
          style={{ background: theme.palette.themePrimary, color: theme.palette.white }}
          onClick={() => setAddCatalogueProduct(true)}>
          დამატება
        </Button>
      </div>
      <Table>
        <TableHead style={{ background: theme.palette.neutralLighterAlt }}>
          <TableRow>
            <TableCell>სახელი</TableCell>
            <TableCell>კატეგორია</TableCell>
            <TableCell>ცვლადი ინგრედიენტები</TableCell>
            <TableCell>ფასი</TableCell>
            <TableCell>თვითღირებულება</TableCell>
            <TableCell>რედაქტირება</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.map((product, idx) => {
            if (!product?.name?.toLowerCase().includes(search?.toLowerCase())) {
              return null;
            }

            return <ProductRow key={`${product.name}_${idx}`} {...product} />;
          })}
        </TableBody>
      </Table>
      <AddCatalogueProduct
        isOpen={addCatalogueProduct}
        closeHandler={() => setAddCatalogueProduct(false)}
        onProceed={addProduct}
      />
    </div>
  );
};
