import { TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import React from 'react';

export const FullReportTable = ({ report }) => {
  return (
    <React.Fragment>
      <TableBody>
        <TableRow key={`${report.name}_${report.quantity}`}>
          <TableCell>{report.name}</TableCell>
          <TableCell>{report.quantity}</TableCell>
          <TableCell>{report.productPrice}₾</TableCell>
          <TableCell>{report.sumPrice}₾</TableCell>
        </TableRow>
      </TableBody>
    </React.Fragment>
  );
};
