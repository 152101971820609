import { LOG_OUT, TOGGLE_BRANCH } from 'redux/actions/userActions';
import { AuthorizationService } from 'services';
import { GET_USER } from './types';

export const getUserDetails = () => async (dispatch) => {
  try {
    let authService = new AuthorizationService();
    let userDetails = await authService.getInfo();
    dispatch({
      type: GET_USER,
      payload: userDetails
    });
    dispatch({
      type: TOGGLE_BRANCH,
      payload: userDetails.branchId
    })
  } catch (e) {
    dispatch({
      type: LOG_OUT
    });
  }
};
