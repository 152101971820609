import styled from 'styled-components';

export const Main = styled.main`
  min-height: 100vh;
`;

export const OrderDetailsContainer = styled.div`
  @media (max-width: 768px) {
    width: 100vw;
    position: fixed;
    left: ${(props) => (props.active ? '0%' : '100%')};
    transition-duration: 0.3s;
  }
`;

export const CartIcon = styled.div`
  display: none;
  color: #00a852;
  & > svg {
    width: 27px;
    height: 27px;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Splitter = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  @media (max-width: 1100px) {
    grid-template-columns: 65% 35%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const Details = styled.div`
  padding: 0 40px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 0px;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 40px;
`;

export const GoBack = styled.div`
  width: 35px;
  height: 35px;
  cursor: pointer;
  & > svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SearchField = styled.div`
  background-color: #f8f8f7;
  padding: 0 14px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  @media (max-width: 768px) {
    max-width: 85%;
    margin: 0 5px;
  }
`;

export const SearchIcon = styled.div`
  width: 22px;
  height: 22px;
  color: grey;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const SearchInput = styled.input`
  padding: 16px 11px;
  background: transparent;
  border: none;
  outline: none;
  width: 320px;
  font-size: 0.99em;
`;

export const Categories = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 25px;
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f8f8f7;
  }

  &::-webkit-scrollbar-thumb {
    background: #00a852;
  }
`;

export const Category = styled.div`
  color: ${(props) => (props.active ? '#00a852' : '#AAAAAA')};
  margin: 0 5px;
  font-size: 1em;
  cursor: pointer;
  font-weight: 500;
  transition-duration: 0.2s;
  border: 2px solid ${(props) => (props.active ? '#00a852' : '#AAAAAA')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 25px;
  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;

export const Products = styled.div`
  padding-top: 55px;
`;

export const ProductsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 65px;
`;

export const Logo = styled.div`
  width: 70px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const HeaderText = styled.div`
  font-size: 2.5em;
  font-weight: bold;
`;

export const ProductsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 30px;
  gap: 50px;
  & > * {
    transition: 0.3s;
    &:hover {
      transform: translateY(-3px);
    }
  }
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
