import React from 'react';
import { TableRow, TableCell, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';
import { useState, useMemo } from 'react';
import dayjs from 'dayjs';

export const OrderHistoryItem = ({ row }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const paymentMethodText = useMemo(() => {
    switch (row.paymentMethod) {
      case 'CASH':
        return 'ქეში';
      case 'TERMINAL':
        return 'ტერმინალი';
      case 'CARD':
        return 'ბარათი';
      default:
        return 'ამოუცნობი';
    }
  }, [row.paymentMethod]);
  return (
    <>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>შენაძენი</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {row.productList.map((val) => (
              <div key={val.id} style={{ margin: '1em', minWidth: 250 }}>
                <h3>{val.name}</h3>
                {val.specificationList &&
                  val?.specificationList.map((product) => (
                    <p key={product.name}>
                      {product.ingredient.name} - {product.specification}
                    </p>
                  ))}
                <p>{val.quantity ?? 1}X</p>
              </div>
            ))}
            {row.description && <div style={{ margin: '1em' }}>კომენტარი: {row.description}</div>}
          </div>
        </DialogContent>
      </Dialog>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        style={{ backgroundColor: row.statusCode.id === 6 ? 'red' : 'white' }}>
        <TableCell>N - {row.code}</TableCell>
        <TableCell>
          {row?.recipientUser?.firstname ? (
            `${row.recipientUser?.firstname} ${row?.recipientUser?.lastname}`
          ) : (
            <Clear />
          )}
        </TableCell>
        <TableCell>{row.clientPhoneNumber || <Clear />}</TableCell>
        <TableCell>{row.address.userAddress || <Clear />}</TableCell>
        <TableCell>{row.branch.addres} {row.branch.city}</TableCell>
        <TableCell>{dayjs(row.recordTime).format('DD/MM/YYYY HH:mm')}</TableCell>
        <TableCell>
          {row.courier?.firstname ? (
            `${row.courier?.firstname} ${row.courier?.lastname}`
          ) : (
            <Clear />
          )}
        </TableCell>
        <TableCell>{row.price}₾</TableCell>
        <TableCell>{paymentMethodText}</TableCell>
        <TableCell>
          <Button color='primary' variant='contained' onClick={() => setIsModalOpen(true)}>
            შენაძენი
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};
