import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f8f8f7;
  padding: 40px 25px;
  position: sticky;
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopPart = styled.div``;

export const Block = styled.div`
  border-bottom: 1px solid #aaaaaa;
  padding-bottom: 20px;
`;

export const BlockHeader = styled.h4`
  font-size: 1.8em;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg{
    width: 32px;
    height: 32px;
    display: none;
    @media (max-width: 768px){
      display: initial;
    }
  }
`;

export const BlockContent = styled.div`
  overflow-y: auto;
  max-height: 350px;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f8f8f7;
  }

  &::-webkit-scrollbar-thumb {
    background: grey;
  }
  @media (max-width: 768px){
    max-height: 420px;
  }
  @media (max-height: 665px){
    max-height: 300px;
  }
`;

export const OrderProductContainer = styled.div`
  margin: 25px 0;
`;

export const OrderProductIngredients = styled.div`
  max-height: ${(props) => (props.active ? '170px' : '0px')};
  transition-duration: 0.4s;
  overflow-y: hidden;
  margin-top: 10px;
  margin-right: 8px;
  @media (max-height: 665px){
    max-height: ${(props) => (props.active ? '120px' : '0px')};
  }
`;

export const OrderProductIngredient = styled.div`
  color: grey;
  text-align: end;
  margin: 6px 0;
`;

export const DropdownContainer = styled.div``;

export const OrderProduct = styled.div`
  display: flex;
  align-items: center;
`;

const OrderProductItem = styled.div`
  margin-right: 15px;
`;

export const OrderImage = styled(OrderProductItem)`
  width: 65px;
  height: 40px;
  border-radius: 10px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
  }
`;

export const OrderName = styled(OrderProductItem)`
  font-weight: 500;
  width: 150px;
  margin-right: 15px;
`;

export const OrderPrice = styled(OrderProductItem)`
  font-weight: 500;
`;

export const OrderQuantity = styled(OrderProductItem)`
  font-weight: 500;
`;

export const OrderIcons = styled(OrderProductItem)`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: auto;
  justify-content: flex-end;
  & > * {
    font-size: 1.3em;
    margin: 0 5px;
    cursor: pointer;
    &:nth-child(2) {
      color: red !important;
    }
    &:nth-child(3) {
      & > svg {
        color: black !important;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const CheckoutButton = styled.button`
  width: 100%;
  padding: 16px;
  background-color: #00a852;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  font-size: 1.08em;
  border: none;
  outline: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 25px;
  & > svg {
    margin-left: 10px;
    font-size: 18px;
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`;

export const TotalText = styled.p`
  font-size: 1.1em;
  font-weight: 500;
`;

export const TotalAmount = styled.p`
  font-size: 1.2em;
  font-weight: 600;
`;
