import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@material-ui/core';
import { DashTernaryModal, Receipt } from 'components';
import { makeStyles } from '@material-ui/styles';
import day from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ka';
import { OrderService } from 'services';
import axios from 'axios';
import { useCallback } from 'react';
import { DELIVERY_PRICE, Roles, theme } from 'data';
import { LoadButton } from 'components/LoadButton/LoadButton';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { TypeColors } from 'data/statusColors';

day.extend(relativeTime);
day.locale('ka');

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon style={{ fontSize: '.9em' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    alignItems: 'center'
  }
}));

const DeliveryPriceModal = ({ onDeliveryApprove, orderId, isModalOpen, closeModal }) => {
  const [price, setPrice] = useState(DELIVERY_PRICE);
  useEffect(() => {
    if (!isModalOpen) {
      setPrice(DELIVERY_PRICE);
    }
  }, [isModalOpen]);

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <DialogTitle>მიტანის საფასური</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex' }}>
          <TextField value={price} onChange={(ev) => setPrice(ev.target.value)} />
          <LoadButton onClick={() => onDeliveryApprove(price)}>დადასტურება</LoadButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const useStyles = makeStyles({
  summary: {
    margin: '0 15px'
  },
  productsGrid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%'
  },
  productItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.9em'
  },
  ingredient: {
    padding: '0 !important'
  },
  loadMore: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center'
  }
});

export const PendingOrders = () => {
  const [expanded, setExpanded] = useState('');
  const [deliveredModal, setDeliveredModal] = useState({
    open: false,
    id: null
  });
  const [loadingIdx, setLoadingIdx] = useState(undefined);
  const receiptRef = useRef(null);
  const [orders, setOrders] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchLoading, setSearchLoading] = useState('');
  const [search, setSearch] = useState('');
  const [deliveryPriceModalId, setDeliveryPriceModalId] = useState(undefined);
  const [orderToPrint, setOrderToPrint] = useState({});
  const [deletedIdx, setDeletedIdx] = useState([]);
  const { statuses } = useSelector((state) => state.products);
  const { role, branchId: _branchId } = useSelector((state) => state.user);
  const { branchId } = useSelector((state) => state.userDetails);
  const latestUnapprovedOrder = useSelector((state) => state.orders.latestUnapprovedOrder);
  const dispatch = useDispatch();
  const orderService = new OrderService();
  const getOrders = useCallback(async (branchId, search) => {
    const res = await orderService.getOrders({
      PageNumber: 1,
      PageSize: 10000,
      statusCodes: [2],
      branchId: role === Roles.admin ? undefined : branchId,
      searchingText: search
    });
    const latestUnapprovedOrder = res
      .slice()
      .sort((a, b) => new Date(a.recordTime).getDate() - new Date(b.recordTime).getDate())
      .find((e) => e.statusCode.id === 2);

    if (latestUnapprovedOrder) {
      dispatch({
        type: 'SET_LATEST_ORDER',
        payload: latestUnapprovedOrder.orderId
      });
    }
    setOrders((prevState) => [...prevState, ...res]);
  }, []);

  useEffect(() => {
    (async () => {
      setSearchLoading(true);
      statuses.length &&
        typeof branchId !== 'undefined' &&
        (await getOrders(statuses, branchId, search));
      setSearchLoading(false);
    })();
    // return () => setOrders([]);
  }, [getOrders, statuses.length, branchId, search]);

  useEffect(() => {
    const refresh = async () => {
      if (search) {
        return;
      }
      if (role !== Roles.admin && !_branchId) {
        return;
      }
      const res = await orderService.getOrders({
        PageNumber: 1,
        PageSize: 10000,
        statusCodes: [2],
        branchId: role === Roles.admin ? undefined : branchId,
        searchingText: search
      });

      const newArr = [...res, ...orders.splice(0, res.length)];
      setOrders(newArr);
    };

    const interval = setInterval(refresh, 5000);

    return () => clearInterval(interval);
  }, [statuses, role, search, _branchId]);

  const classes = useStyles();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDelivery = () => {
    alert(deliveredModal.id);
  };

  const reactToPrintContent = React.useCallback(() => {
    return receiptRef.current;
  }, [receiptRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Receipt',
    onBeforeGetContent: () => void 0,
    onBeforePrint: () => void 0,
    onAfterPrint: () => {},
    removeAfterPrint: true
  });

  const onStatusChange = async (ev, order, statusCodeId, orderType) => {
    ev.stopPropagation();
    if (statusCodeId === 3 && orderType === 'DELIVERY' && !order.createdByAdmin) {
      setDeliveryPriceModalId(order.orderId);
    }
    const res = await axios.put(
      `/api/Order/UpdateOrderStatusCode?orderId=${order.orderId}&statusCodeId=${statusCodeId}`
    );
    if (res.ok) {
      let newUnapproved = orders
        .slice()
        .flatMap((e) => (e.orderId === order.orderId ? [] : e))
        .sort((a, b) => new Date(a.recordTime).getDate() - new Date(b.recordTime).getDate())
        .find((e) => e.statusCode.id === 2);
      if (order.orderId === latestUnapprovedOrder) {
        dispatch({
          type: 'SET_LATEST_ORDER',
          payload: newUnapproved?.orderId || null
        });
      }
    }
    setOrders(
      orders.map((val) => {
        if (val.orderId === order.orderId) {
          return {
            ...val,
            statusCode: {
              id: statusCodeId
            }
          };
        }
        return val;
      })
    );
    if (statusCodeId === 3) {
      setOrderToPrint(res.data.order);
      handlePrint();
    }
  };

  const onDeliveryApprove = async (fee) => {
    const res = await axios.post(
      `/api/Order/ChangeDeliveryFee?orderId=${deliveryPriceModalId}&fee=${fee}`
    );
    if (res.ok) {
      let newUnapproved = orders
        .slice()
        .flatMap((e) => (e.orderId === deliveryPriceModalId ? [] : e))
        .sort((a, b) => new Date(a.recordTime).getDate() - new Date(b.recordTime).getDate())
        .find((e) => e.statusCode.id === 2);
      if (deliveryPriceModalId === latestUnapprovedOrder) {
        dispatch({
          type: 'SET_LATEST_ORDER',
          payload: newUnapproved?.orderId || null
        });
      }
      setOrders(
        orders.map((val) => {
          if (val.orderId === deliveryPriceModalId) {
            return {
              ...val,
              statusCode: {
                id: 3
              }
            };
          }
          return val;
        })
      );
      setDeliveryPriceModalId(null);
      setOrderToPrint(res.data.order);
      handlePrint();
    }
  };

  const deleteOrder = async (orderId) => {
    setLoadingIdx(orderId);
    const res = await orderService.deleteOrder(orderId);
    setDeletedIdx((prevState) => [...prevState, orderId]);
    setLoadingIdx(undefined);
  };

  const statusOrders = {
    2: 3,
    3: 4,
    4: 5
  };

  return (
    <div>
      <DashTernaryModal
        handleAgree={handleDelivery}
        handleClose={(e) => setDeliveredModal({ open: false, id: null })}
        open={deliveredModal.open}
        title='დარწმუნებული ხარ?'
        warning='დარწმუნებული ხარ რომ პროდუქტი ჩაბარდა?'
      />

      <DeliveryPriceModal
        onDeliveryApprove={onDeliveryApprove}
        isModalOpen={deliveryPriceModalId}
        closeModal={() => setDeliveryPriceModalId(undefined)}
      />

      <div style={{ marginBottom: '2em', textAlign: 'center' }}>
        <TextField
          variant='outlined'
          value={searchInput}
          label='ძიება'
          onChange={(ev) => setSearchInput(ev.target.value)}
        />
        <LoadButton
          variant='contained'
          color='primary'
          onClick={() => {
            setOrders([]);
            setSearch(searchInput);
          }}
          loading={searchLoading}>
          ძებნა
        </LoadButton>
      </div>

      {orders.map((val) => {
        if (deletedIdx.includes(val.orderId)) {
          return null;
        }

        return (
          <Accordion
            expanded={expanded === val.orderId}
            onChange={handleChange(val.orderId)}
            sx={{ backgroundColor: TypeColors[val.orderType] }}>
            <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
              <Typography>
                შეკვეთა: N{val.code} {val.orderType === 'DELIVERY' ? '- მიტანით' : ''}
                {val.orderType === 'INSIDE' ? '- დარბაზში' : ''}
                {val.orderType === 'TAKEAWAY' ? '- წასაღებად' : ''}
              </Typography>
              <Typography
                style={{ marginLeft: 'auto', marginRight: 40 }}
                sx={{ color: 'text.secondary' }}></Typography>
              <Button
                variant='contained'
                color='primary'
                style={{ marginRight: '2em' }}
                onClick={() => {
                  setOrderToPrint(val);
                  setTimeout(() => handlePrint(), 300);
                }}>
                ამობეჭდვა
              </Button>
              {[Roles.admin, Roles.administrator, Roles.manager].includes(role) ? (
                <LoadButton
                  variant='contained'
                  color='primary'
                  style={{ marginRight: '2em' }}
                  loading={loadingIdx === val.orderId}
                  onClick={() => deleteOrder(val.orderId)}>
                  წაშლა
                </LoadButton>
              ) : null}
              <Button
                variant='contained'
                color='primary'
                style={{
                  marginRight: '2em',
                  display: val.statusCode.id === 8 ? 'none' : 'initial',
                  wordBreak: 'break-word'
                }}
                loading={false}
                onClick={(ev) => onStatusChange(ev, val, 6, val.orderType)}>
                გაუქმება
              </Button>
              <FormControl style={{ width: 150 }}>
                <Button
                  variant='contained'
                  color='primary'
                  style={{
                    display: val.statusCode.id === 8 ? 'none' : 'initial'
                  }}
                  onClick={(ev) =>
                    val.orderType === 'DELIVERY' && val.statusCode.id === 5
                      ? onStatusChange(ev, val, 7, val.orderType)
                      : val.orderType !== 'DELIVERY' && val.statusCode.id === 5
                      ? onStatusChange(ev, val, 8, val.orderType)
                      : onStatusChange(ev, val, statusOrders[val.statusCode.id], val.orderType)
                  }>
                  {val.orderType === 'DELIVERY' && val.statusCode.id === 5
                    ? statuses.find((e) => e.id === 7)?.name
                    : val.orderType !== 'DELIVERY' && val.statusCode.id === 5
                    ? statuses.find((e) => e.id === 8)?.name
                    : statuses.find((e) => e.id === statusOrders[val.statusCode.id])?.name}
                </Button>
              </FormControl>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.summary}>
                <div className={classes.productsGrid}>
                  <div>
                    <div className={classes.productItem}>
                      <p>ფილიალი</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p> {val.branch.addres} {val.branch.city}</p>
                    </div>
                    <div className={classes.productItem}>
                      <p>წინასწარ გადახდა</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p>{val.paymentMethod === 'CARD' ? 'კი' : 'არა'}</p>
                    </div>
                    <div className={classes.productItem}>
                      <p>შეკვეთის დრო</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p>{day(val.recordTime).format('MM/DD/YYYY HH:mm')}</p>
                    </div>
                    {val.paymentMethod ? (
                      <span>
                        გადახდის ტიპი: {val.paymentMethod === 'TERMINAL' ? 'ტერმინალი' : 'ქეში'}
                      </span>
                    ) : null}
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <div className={classes.productItem}>
                      <p>მისამართი</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p>{val.address.userAddress}</p>
                    </div>
                    <div className={classes.productItem}>
                      <p>ნომერი</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <a href={`tel:+995${val.clientPhoneNumber}`}>{val.clientPhoneNumber}</a>
                    </div>
                  </div>
                  {!!val.deliveryPrice && (
                    <div className={classes.productItem}>
                      <p>მიტანის საფასური</p>
                      <span style={{ margin: '0 5px' }}>-</span>
                      <p>{val.deliveryPrice}₾</p>
                    </div>
                  )}
                </div>
                <Divider style={{ margin: '15px 0' }} />
                <div>
                  {val.productList.map((val) => {
                    return (
                      <List>
                        <ListItem className={classes.ingredient}>
                          <ListItemText>
                            პროდუქტი - {val.name} - {val.quantity}X
                          </ListItemText>
                          <List>
                            {val.specificationList.map((val) => {
                              return (
                                <ListItem className={classes.ingredient}>
                                  <ListItemText
                                    secondary={`${val.ingredient.name} - ${val.specification}`}
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </ListItem>
                      </List>
                    );
                  })}
                  {val.courier && (
                    <List>
                      <ListItem className={classes.ingredient}>
                        <ListItemText>
                          კურიერის სრული სახელი - {val.courier.firstname} {val.courier.lastname}
                        </ListItemText>
                      </ListItem>
                      <ListItem className={classes.ingredient}>
                        <ListItemText>კურიერის ნომერი - {val.courier.phoneNumber}</ListItemText>
                      </ListItem>
                    </List>
                  )}
                  <List>
                    <ListItem className={classes.ingredient}>
                      <ListItemText>ფასი - {val.price}₾</ListItemText>
                    </ListItem>
                  </List>
                  <List>
                    <ListItem className={classes.ingredient}>
                      <ListItemText>კომენტარი - {val.description}</ListItemText>
                    </ListItem>
                  </List>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Receipt ref={receiptRef} {...orderToPrint} />
    </div>
  );
};
