export const StatusColors = {
  2: 'red',
  3: 'green'
};

export const TypeColors = {
  DELIVERY: 'yellow',
  TAKEAWAY: 'orange',
  INSIDE: 'green'
};
