import { LOG_IN, LOG_OUT, TOGGLE_BRANCH } from './actions/userActions';

const fromLS = JSON.parse(localStorage.getItem('user'));

const initialState = fromLS ?? {
  username: '',
  isLoggedIn: false,
  branchId: undefined,
  role: '',
  id: ''
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      localStorage.setItem(
        'user',
        JSON.stringify({
          username: action.payload.username,
          isLoggedIn: true,
          role: action.payload.role,
          id: action.payload.id
        })
      );
      return {
        ...state,
        username: action.payload.username,
        role: action.payload.role,
        isLoggedIn: true,
        branchId: 0,
        id: action.payload.id
      };

    case LOG_OUT:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return {
        username: '',
        isLoggedIn: false
      };

    case TOGGLE_BRANCH:
      return {
        ...state,
        branchId: action.payload
      };

    default:
      return state;
  }
};

export default userReducer;
